import {Button, Icon, useColorModeValue} from "@chakra-ui/react";
import React from "react";


export default function ActionButton({icon, color, ...rest}: {
    icon: any
    color?: string
    [x: string]: any
}) {
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});
    const iconColor = color ||'secondaryGray.800';
    return (<Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'
                {...rest}
                >
                <Icon as={icon} color={iconColor} w='24px' h='24px'/>
            </Button>)
}
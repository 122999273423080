import {StudentRecordTable} from "./StudentRecordTable";
import React from "react";

export function StudentByHandicap({section, data}: {
    section: string,
    data: StudentsRecord,

}) {
    return <StudentRecordTable
        id={'by_handicap'}
        section={section}
        subsection_attribute={'kind'}
        subsection={'disabled_by_age'}
        title={'Effectif des élèves handicapés'}
        helpMessage={"Répartition des élèves handicapés"}
        data={data}
    />
}
// chakra imports
import {Box, Button, Flex, Stack, useToast} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {schoolsApi, useUpdatePartialSchoolMutation} from "../../../services/Schools";
import {toastWrapper} from "../../../utils/utilsFunction";
import {getUserInfo} from "../../../slices/selector";
import {Loader} from "../../Loader/Loader";

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
    const {routes} = props;
    const {editionInProgress, editedSection} = useSelector<any, EditionState>((state: any) => state.edition);
    const [updatePartialSchool, {isLoading}] = useUpdatePartialSchoolMutation()
    const toast = useToast()
    const dispatch = useDispatch();

    const school_id  = useSelector(getUserInfo).detail.school_id
    // @ts-ignore
    const cachedData = useSelector((state) =>
        schoolsApi.endpoints.getPartialSchool.select({
            school_id: school_id,
            section: editedSection
            // @ts-ignore
        })(state)
    );

    const onClickSubmit = async () => {
        try {

            const {data} = cachedData

            const response = await updatePartialSchool({
                school_id: school_id,
                section: editedSection, data: data.detail.data
            })
            // @ts-ignore
            if (response?.data) {
            // @ts-ignore

                const message = response?.data.detail.msg
                dispatch({type: 'edition/endEdition'})
                toastWrapper(
                    toast,
                    "success",
                    "Bonne nouvelle",
                    // @ts-ignore
                    message,
                    "success"
                )
            } else {
                console.log(response)
                toastWrapper(
                    toast,
                    "error",
                    "Erreur",
                    // @ts-ignore
                    "Une erreur s'est produite, veuillez réesayer.",
                    "error",)
            }
        } catch (error: any) {
            console.log(error)
            toastWrapper(
                toast,
                "error-not-known",
                "Erreur Inconnue",
                "Une erreur s'est produite, veuillez réesayer.",
                "error"
            )
        }
    }
    return (
        <Flex  direction='column' height='100%' pt='25px' borderRadius='30px'


        >
            <Brand/>
            <Stack height='100%' direction='column' mt='8px' mb='auto'>
                <Box height='100%' ps='20px' pe={{lg: '16px', '2xl': '16px'}}>
                    <Flex flexDirection={"column"} h={"100%"} >

                        <Flex flexDirection={"column"} mt={"10px"} h={"40vh"} overflow={"auto"}
                        >

                            <Links saveUpdate={onClickSubmit} routes={routes}/>

                        </Flex>
  <Flex flexDirection={"column"}>
                            <Button my={"10px"} bg={'brand.900'} color={"white"}>Remplissage guidée</Button>
                            <Button onClick={onClickSubmit} disabled={!editionInProgress}  bg={'green.500'}
                                    color={"white"}>{isLoading ? <Loader/>: 'Soumettre modification'}</Button>

                        </Flex>


                    </Flex>

                </Box>
            </Stack>


        </Flex>
    );
}

export default SidebarContent;

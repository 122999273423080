// slices/collapseSlice.js
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
// @ts-ignore

    expandedBlocks: [], // Liste des IDs des blocs dépliés
};

const collapseSlice = createSlice({
    name: "collapse",
    initialState,
    reducers: {
        expandBlock: (state, action) => {
            if (!state.expandedBlocks.includes(action.payload)) {
                state.expandedBlocks.push(action.payload);
            }
        },
        collapseBlock: (state, action) => {
            state.expandedBlocks = state.expandedBlocks.filter(id => id !== action.payload);
        },
    },
});

export const {expandBlock, collapseBlock} = collapseSlice.actions;
export default collapseSlice.reducer;

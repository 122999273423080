import {Box, SimpleGrid} from '@chakra-ui/react';
import MiniStatistics from '../../../components/card/MiniCard';
import * as React from "react";
import FormComponent from "../../../components/form/FormComponent";
import {LocaTerri} from "./components/LocaTerri";
import {LocaPeda} from "./components/LocaPeda";
import {ReferenceYear} from "./components/ReferenceYear";
import {StatusAndType} from "./components/StatusAndType";
import {Loader} from "../../../components/Loader/Loader";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {useSelector} from "react-redux";
import {getUserInfo} from "../../../slices/selector";
import {Search} from "../../../components/SearchBox/Search";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";
import ScrollLink from "../../../components/ScrollLink";


export default function SchoolIdentification() {
    const section_name = 'id_and_location'
    const school_name = useSelector(getUserInfo).detail.school_name
    const {section_data: id_and_location, isLoading} = useGetSectionData<IdAndLocation>(section_name);


    return (

        <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
                <>
                    <SimpleGrid columns={{base: 1, md: 1, lg: 1}} gap='20px' mb='20px'>

                        <MiniStatistics name='Nom école' value={isLoading ? 'Chargement' : school_name}/>
                    </SimpleGrid>
                    <SimpleGrid columns={{base: 1, md: 1, xl: 2}} gap='20px' mb='20px'>
                        <StatusAndType section={section_name} loading={isLoading} data={id_and_location}/>

                    </SimpleGrid>
                    <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>

                        <ReferenceYear section={section_name} data={id_and_location}/>
                    </SimpleGrid>

                    <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>
                        <LocaTerri section={section_name} loading={isLoading} data={id_and_location}/>
                        <LocaPeda section={section_name} loading={isLoading} data={id_and_location}/>
                    </SimpleGrid></>}

        </Box>
    );
}

import "instantsearch.css/themes/satellite.css";
import { useInstantSearch, useSearchBox, UseSearchBoxProps} from "react-instantsearch";

import {useState} from "react";
import {SearchInput} from "../navbar/searchBar/SearchInput";
import {Loader} from "../Loader/Loader";


export const ChakraSearchBox = (props: UseSearchBoxProps) => {
    const {query, refine} = useSearchBox(props);
    const {status} = useInstantSearch();
    const [inputValue, setInputValue] = useState(query);
    const isSearchStalled = status === 'stalled';

    function setQuery(newQuery: string) {
        setInputValue(newQuery);

        refine(newQuery);
    }

    return (
        <>

            <SearchInput
                mb={4}
                autoFocus={true}
                value={inputValue}
                onChange={(event) => {
                    setQuery(event.currentTarget.value);
                }}
                w={"100%"}/>
            {isSearchStalled && <Loader/>}

        </>
    );
};
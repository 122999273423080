import {Box, SimpleGrid} from "@chakra-ui/react";

import React from "react";

import {Main} from "./components/Main";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";

export function TeachingStaff() {
    const section_name = 'teaching_staff'
    const {section_data: teaching_staff, isLoading} = useGetSectionData<TeachingStaffRequest>(section_name);
    return (
        <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :

                <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>
                    <Main data={teaching_staff} section={section_name}/>
                </SimpleGrid>}

        </Box>
    )
}
import {mode} from '@chakra-ui/theme-tools';
// Supports weights 100-900
import '@fontsource-variable/inter';

export const globalStyles = {
    colors: {
        brand:
            {
                50: '#f2f2f2',
                100: '#d9d9d9',
                200: '#bfbfbf',
                300: '#a6a6a6',
                400: '#8c8c8c',
                500: '#737373',
                600: '#595959',
                700: '#404040',
                800: '#262626',
                900: '#0d0d0d',
            },
        brandScheme: {
            100: '#d9d9d9',
            200: '#bfbfbf',
            300: '#a6a6a6',
            400: '#8c8c8c',
            500: '#737373',
            600: '#595959',
            700: '#404040',
            800: '#262626',
            900: '#0d0d0d',
        },
        brandTabs: {
            100: '#E9E3FF',
            200: '#422AFB',
            300: '#422AFB',
            400: '#422AFB',
            500: '#422AFB',
            600: '#3311DB',
            700: '#02044A',
            800: '#190793',
            900: '#02044A'
        },
        secondaryGray: {
            100: '#E0E5F2',
            200: '#E1E9F8',
            300: '#F4F7FE',
            400: '#E9EDF7',
            500: '#8F9BBA',
            600: '#A3AED0',
            700: '#707EAE',
            800: '#707EAE',
            900: '#1B2559'
        },
        red: {
            100: '#FEEFEE',
            500: '#EE5D50',
            600: '#E31A1A'
        },
        blue: {
            50: '#EFF4FB',
            500: '#3965FF'
        },
        orange: {
            100: '#FFF6DA',
            500: '#FFB547'
        },
        green: {
            100: '#E6FAF5',
            500: '#01B574'
        },
        navy: {
            50: '#d0dcfb',
            100: '#aac0fe',
            200: '#a3b9f8',
            300: '#728fea',
            400: '#3652ba',
            500: '#1b3bbb',
            600: '#24388a',
            700: '#1B254B',
            800: '#111c44',
            900: '#0b1437'
        },
        gray: {
            100: '#FAFCFE'
        },
        base: {
            white: "#FFFFFF",
            black: "#000000"
        }
    },
    fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
      fontSizes: {
    xs: "0.75rem",
    sm: "0.880rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
    styles: {
        global: (props: any) => ({
            body: {
                overflowX: 'hidden',
                bg: mode('secondaryGray.300', 'navy.900')(props),
                fontFamily: 'DM Sans',
                // Text color
                color: mode('base.black', 'white.1000')(props)
            },
            p: {
                fontWeight: 'thin',
                color: 'gray.900',
                fontSize: 'sm'
            },
            input: {
                color: 'gray.700'
            },
            html: {
                fontFamily: 'Inter Variable'
            }
        })
    }
};

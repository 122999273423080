/* eslint-disable */

import {NavLink, useLocation, useHistory} from 'react-router-dom';
// chakra imports
import {
    Box,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    HStack,
    Text,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ChakraAlerteDialog} from "../../Modal/ChakraAlerteDialog";
import {schoolsApi} from "../../../services/Schools";
import {getUserInfo} from "../../../slices/selector";
import {resetEdition} from "../../../thunks/resetEdition";


function ProgressFilling({value}: {
    value: number
}) {
    const color = function () {
        if (value < 30) {
            return 'red.500'
        } else if (value < 60) {
            return 'yellow.500'
        } else {
            return 'green.500'
        }
    }
    return (
        <CircularProgress trackColor={'secondaryGray.300'}
                          size={"40px"} capIsRound={true} value={value}
                          color={color()}>
            <CircularProgressLabel>{Math.round(value)}%</CircularProgressLabel>
        </CircularProgress>)
}

export function SidebarLinks(props: {
    routes: RoutesType[];
    saveUpdate: () => void;
}) {
    //   Chakra color mode
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const activeColor = useColorModeValue('gray.700', 'white');
    const inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
    const activeIcon = useColorModeValue('brand.500', 'white');
    const textColor = useColorModeValue('secondaryGray.500', 'white');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const editionStatus = useSelector((state: any) => state.edition);
    const {routes, saveUpdate} = props;
    const [onGoingRoute, setOnGoingRoute] = React.useState<string>('');
    const school_id = useSelector(getUserInfo).detail.school_id
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string) => {
        return location.pathname.includes(routeName);
    };
    const {data} = useSelector((state) =>
        schoolsApi.endpoints.getCompletionScore.select({
            school_id: school_id,
            // @ts-ignore
        })(state)
    );

    // this function creates the links from the secondary accordions (for example auth -> sign-in -> id_and_location)
    const createLinks = (
        routes: RoutesType[],
    ) => {
        return routes.map(
            (
                route: RoutesType,
                index: number
            ) => {
                if (route.layout === '/formulaire') {
                    return (
                        <NavLink
                            className={"sidebar-link"}
                            key={index} to={route.layout + route.path} onClick={(e) => {
                            e.preventDefault();
                            if (editionStatus.editionInProgress) {
                                setOnGoingRoute(route.layout + route.path)
                                onOpen()
                                return
                            }
                            history.push(route.layout + route.path);
                        }}>
                            {route.icon ? (
                                <Box>
                                    <HStack
                                        spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                        py='5px'
                                        ps='10px'>
                                        <Flex w='100%' alignItems='center' justifyContent='center'>
                                            <Box
                                                h='36px'
                                                w='4px'
                                                bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
                                                borderRadius='5px'
                                            />
                                            <Box
                                                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                                me='18px'>
                                                {route.icon}
                                            </Box>
                                            <Text
                                                me='auto'
                                                w={"60%"}
                                                color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                                                fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
                                                {route.name}
                                            </Text>
                                            <Box
                                                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                                mr='10px' p={1}>
                                                <ProgressFilling
                                                    value={data?.detail.completion_score[route.identifier]}/>

                                            </Box>
                                        </Flex>

                                    </HStack>
                                </Box>
                            ) : (
                                <Box>
                                    <HStack
                                        spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                        py='5px'
                                        ps='10px'>
                                        <Text
                                            me='auto'
                                            color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                                            fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
                                            {route.name}
                                        </Text>
                                        <Box h='36px' w='4px' bg='brand.400' borderRadius='5px'/>
                                    </HStack>
                                </Box>
                            )}
                        </NavLink>
                    );
                }
            }
        );
    };
    //  BRAND
    return <>
        <ChakraAlerteDialog isOpen={isOpen}
                            message={"Vous avez des données non sauvegardées souhaitez vous continuer (les données non sauvegardées seront perdues) ?"}
                            acceptText={"Oui Continuer"}
                            cancelText={"Non Rester"}
                            onAccept={() => {
                                // @ts-ignore
                                dispatch(resetEdition())
                                history.push(onGoingRoute)
                                onClose()
                            }}
                            onClose={() => {
                                setOnGoingRoute('')
                                onClose()
                            }} title={"Modification en cours"}
        />
        {createLinks(routes)}</>
}

export default SidebarLinks;

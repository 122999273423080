// authActions.js
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {SERVER_URL} from "../config";

async function login(school_code: string): Promise<LoginResponse> {
    const option = {
        headers: {
            "Content-Type": "application/json",
        }
    }
    const body: LoginRequest = {
        school_code: school_code
    }
    const url = `${SERVER_URL}/auth/login`
    const {data} = await axios.post(url, body, option);
    return data;
}

export const userLogin = createAsyncThunk(
    'auth/login',
    async (credentials: LoginRequest, {rejectWithValue}) => {
        const {school_code} = credentials
        try {

            const {detail} = await login(school_code)
            // store user's token in local storage
            localStorage.setItem('userToken', detail.auth_token)
            return detail
        } catch (error: any) {
            if (error.response && error.response.data.detail) {
                return rejectWithValue(error.response.data.detail)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
import React from 'react';


import useGetSectionData from "../../../hooks/useGetSectionData";
import {Main} from "./components/Main";
import {Loader} from "../../../components/Loader/Loader";
import {Box, SimpleGrid} from "@chakra-ui/react";

export default function OfficeAndFurniture() {
    const section_name = 'office_and_furniture'

    const {section_data: office_and_furniture, isLoading} = useGetSectionData<OfficeAndFurniture>(section_name);

    return (
        <>
            <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
                {isLoading ? <Loader/> :
                    <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>

                        <Main data={office_and_furniture} section={section_name}/>
                    </SimpleGrid>}
            </Box>
        </>
    );
}

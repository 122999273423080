// Chakra imports
import {Box, Flex, Text, Checkbox, Stack, Radio, RadioGroup} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import Help from "../help/Help";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {schoolsApi} from "../../services/Schools";
import {updateObjectByPath} from "../../utils/utilsFunction";
import useSyncReduxCache from "../../hooks/useSyncReduxCache";

export default function Checklist(props: {
    type?: "checklist" | "radio",

    help: string,
    name: string,
    listItems: string[],
    section?: string,
    selectedItems?: string,
    dataPath?: string,
    [x: string]: any
}) {

    const {name, help, listItems, section, type, selectedItems, dataPath, ...rest} = props;

    const [value, setValue] = useSyncReduxCache(selectedItems, section, dataPath);

    return (
        <Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex alignItems='center' w='100%' mb='30px'>


                <Text fontSize='lg' fontWeight='bold'>
                    {name}
                </Text>
                <Help helpMessage={help} ms={"auto"}/>
            </Flex>
            <Box px='11px' w='100%'>
                {
                    type === "checklist" ?
                        listItems.map((item, index) => (
                            <Flex w='100%' mb='20px' key={index}>
                                <Checkbox me='16px' colorScheme='brandScheme'/>
                                <Text fontSize='md' textAlign='start'>
                                    {item}
                                </Text>

                            </Flex>
                        )) :
                        <RadioGroup onChange={setValue} value={value}>
                            <Stack direction='column'>
                                {listItems.map((item, index) => <Radio key={index} value={item}>{item}</Radio>)}

                            </Stack>
                        </RadioGroup>}


            </Box>
        </Card>
    );
}

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {SERVER_URL} from "../config";

export const schoolsApi = createApi({
    reducerPath: 'professeurApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${SERVER_URL}/schools`,
    }),
    tagTypes: ['schools',  'SchoolSection'],
    endpoints: (builder) => ({

        getPartialSchool: builder.query<{
            detail: {
                school_id: string,
                data: any
            }
        }, { school_id: string, section: string }>({
            query: (data: { school_id: string, section: string }) => ({
                url: `/${data.school_id}/${data.section}`,
            }),
            // @ts-ignore
            providesTags: (result, error, arg) => [
                {type: 'SchoolSection', id: `${arg.school_id}-${arg.section}`}
            ],
        }),
        getCompletionScore: builder.query<{
            detail: {
                school_id: string,
                completion_score: any
            }
        }, { school_id: string }>({
            query: (data: { school_id: string }) => ({
                url: `/${data.school_id}/completion-score`,
            }),
            // @ts-ignore
            providesTags: (result, error, arg) => [
                {type: 'SchoolSection', id: arg.school_id}
            ],
        }),

        updatePartialSchool: builder.mutation({
            query: (data: { school_id: string, section: string, data: any }) => ({
                url: `/${data.school_id}/${data.section}`,
                method: 'PATCH',
                body: data.data

            }),
            // @ts-ignore
            invalidatesTags: (result, error, arg) => [
                {type: 'SchoolSection', id: `${arg.school_id}-${arg.section}`}
            ],

        }),


    })
})

export const {
    useGetPartialSchoolQuery,
    useGetCompletionScoreQuery,
    useUpdatePartialSchoolMutation

} = schoolsApi
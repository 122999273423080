import {Flex, useDisclosure} from "@chakra-ui/react";

import ChakraModal from "../../../../components/Modal/ChakraModal";
import PersonnelCreation from "./PersonnelCreation";
import Personnel from "./Personnel";
import {ChakraAlerteDialog} from "../../../../components/Modal/ChakraAlerteDialog";
import AddButton from "../../../../components/AddButton/AddButton";
import React from "react";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import ActionButton from "../../../../components/icons/ActionButton";
import {CiEdit, CiTrash} from "react-icons/ci";
import {TableData} from "../../../../components/dataTable/TableData";

export function Main({section, data}: {
    section: string,
    data: TeachingStaffRequest
}) {

    const {isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd} = useDisclosure()
    const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure()
    const {isOpen: isOpenEdition, onOpen: onOpenEdition, onClose: onCloseEdition} = useDisclosure()
    const [selectedRow, setSelectedRow] = React.useState<Staff | null>(null)
    const [value,
        setValue] = useSyncReduxCache(
        data.personnel,
        section,
        "detail.data.personnel");

    const columns: DataColumn[] = [
        {
            header: "Matricule",
            accessor: "matricule",
            kind: "text",
            isEditable: false,
            width: '20%'
        },
        {
            header: "Nom",
            accessor: "name",
            kind: "text",
            isEditable: true,
            width: '30%'
        },
        {
            header: "Prénom",
            accessor: "surname",
            kind: "text",
            isEditable: true,
             width: '30%'
        },
        {
            header: "Sexe",
            accessor: "sexe",
            kind: "select",
            isEditable: true,
            width: '20%',
            options: [
                {value: "M", label: "M"},
                {value: "F", label: "F"}
            ]
        },


        {
            header: "Actions",
            kind: 'action',
            isEditable: true,
            component: ({row}) => <Flex align={"center"}>
                        <ActionButton icon={CiEdit} onClick={
                            () => {
                                setSelectedRow(row)
                                onOpenEdition()
                            }
                        } mr={2}/>
                        <ActionButton bg={"red.500"} onClick={() => {
                            setSelectedRow(row)
                            onOpenDelete()
                            /* show the clicked row */

                        }} color={"white"} icon={CiTrash}/>
                    </Flex>

        }
    ]
    return (
        <>

            <ChakraModal isOpen={isOpenAdd} onClose={onCloseAdd}>

                <PersonnelCreation
                    onClose={onCloseAdd}
                    section={section}
                    setPersonnelList={setValue}
                    helpMessage={"Ajout er un personnel"}
                    title={"Ajouter un personnel"}
                />
            </ChakraModal>
            <ChakraModal isOpen={isOpenEdition} onClose={onCloseEdition}>
                <Personnel
                    onClose={onCloseEdition}
                    selectedPersonnel={selectedRow}
                    setPersonnelList={setValue}
                    section={section}
                    helpMessage={"Modifier un enseignant"}
                    title={"Ajouter un enseignant"}
                />
            </ChakraModal>
            <ChakraAlerteDialog isOpen={isOpenDelete} onClose={onCloseDelete}
                                onAccept={() => {
                                    setValue((prev: Staff[]) => {
                                        return prev.filter((person) => person !== selectedRow)
                                    })
                                    onCloseDelete()
                                }}
                                title={"Supprimer cet enseignant"}
                                message={`Voulez vous supprimer ${selectedRow?.surname} ${selectedRow?.name}`}/>
            <TableData<Staff>
                id={'teaching_staff'}
                columns={columns}
                setValue={setValue}
                data={value}
                helperComponent={<AddButton onClick={onOpenAdd} message={"Ajouter un personnel"}/>}
                title={"Liste des enseignants"} helpMessage={"Liste des enseignants"}/>


        </>
    )
}
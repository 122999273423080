import React from 'react';

// Chakra imports
import {

    SimpleGrid, Box,

} from '@chakra-ui/react';


import FormComponent from "../../../components/form/FormComponent";
import {Proximity} from "./components/Proximity";
import {Accessibility} from "./components/Accessibility";
import {ServiceAndEnvironnement} from "./components/ServiceAndEnvironnement";
import {NearbyEstablishments} from "./components/NearbyEstablishments";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";

export default function SocioEnvironnement() {
    const section_name = 'socio_environnement'
    const {section_data: socio_environnement, isLoading} = useGetSectionData<SocioEnvironnement>(section_name);

    return (
        <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
                <>
                    <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>


                        <Proximity section={section_name} data={socio_environnement}/>

                        <Accessibility section={section_name} data={socio_environnement}/>
                        <ServiceAndEnvironnement section={section_name} data={socio_environnement}/>
                        <NearbyEstablishments section={section_name} data={socio_environnement}/>

                    </SimpleGrid>
                </>
            }
        </Box>
    );
}

import {Flex, Input, Select, Text} from "@chakra-ui/react";
import * as React from "react";

import {Loader} from "../../../../components/Loader/Loader";
import circonscription from "../../../../assets/json/circ.json"

import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import useFormValidator from "../../../../hooks/useFormValidator";
import FormComponent from "../../../../components/form/FormComponent";

interface LocaPedaInterface {
    circonscription_scolaire: string;
    zone_pedagogique: number;
    unite_pedagogique: string;
}

export function LocaPeda({data, loading, section}: {
    data: IdAndLocation | null,
    section: string,
    loading: boolean
}) {

    const [value,
        setValue] = useSyncReduxCache(data.loca_peda,
        section,
        "detail.data.loca_peda");
    const {validationStatus} = useFormValidator<LocaPedaInterface>(value, {});

    const render = () => {
        if (loading) {
            return <Loader/>
        } else {
            const formElements: FormElement[] = [

                // {
                //     id: "circonscription_scolaire",
                //     description: "La circonscription scolaire est une entité administrative qui regroupe plusieurs écoles primaires. Elle est dirigée par un Inspecteur de l'Enseignement Primaire.",
                //     title: 'Circonscription Scolaire',
                //     component: <Select
                //         maxWidth={"300px"}
                //         value={value.circonscription_scolaire}
                //         onChange={(e) => {
                //             setValue((prev) => {
                //                 return {...prev, circonscription_scolaire: e.target.value}
                //             })
                //         }}
                //         placeholder={"Selectionner une circonscription scolaire"}
                //     >
                //         {
                //             // @ts-ignore
                //             data.loca_territoire.departement &&
                //             // @ts-ignore
                //             data.loca_territoire.commune &&
                //             // @ts-ignore
                //             circonscription[data.loca_territoire.departement][data.loca_territoire.commune].map((item, index) => (
                //                 <option key={index}
                //                         value={item}>{item}</option>
                //             ))
                //         }
                //
                //     </Select>
                // },
                {
                    id: "zone_pedagogique",
                    description: "La zone pédagogique est une entité administrative qui regroupe plusieurs écoles primaires. Elle est dirigée par un Inspecteur de l'Enseignement Primaire.",
                    title: 'Zone Pédagogique',
                    component: <Select
                        maxWidth={"300px"}
                        value={value.zone_pedagogique}
                        onChange={(e) => {
                            setValue((prev) => {
                                return {
                                    ...prev, zone_pedagogique:
                                        e.target.value === "" ? null :
                                            Number(e.target.value)
                                }
                            })
                        }}
                        placeholder={"Selectionner une zone pédagogique"}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </Select>
                },
                {
                    id: "unite_pedagogique",
                    description: "L'unité pédagogique est une entité administrative qui regroupe plusieurs écoles primaires.",
                    title: 'Unité Pédagogique',
                    component: <Input

                        value={value.unite_pedagogique}
                        onChange={(e) => {
                            setValue((prev) => {
                                return {...prev, unite_pedagogique: e.target.value}
                            })
                        }}
                        maxWidth={"300px"}
                    />
                }
            ]
            return <FormComponent
                id={"loca_peda"}
                status={validationStatus.status}
                isCollapsable={true}
                title={"Localisation dans le Réseau d'Animation Pédagogique"}>
                {
                    formElements.map((item, index) => (
                        <Flex

                            w='100%' mb='20px' key={index} alignItems={"center"}
                              justifyContent={"space-between"}>

                            <Text
                                id={item.id}
                                data-description={item.description}
                                w={"200px"} fontSize='md' textAlign='start'>
                                {item.title}
                            </Text>
                            {item.component}
                        </Flex>
                    ))
                }
            </FormComponent>
        }
    }

    return render()

}
import {Flex, Text} from "@chakra-ui/react";
import * as React from "react";
import {DistanceSelect} from "./DistanceSelect";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";

interface ProximityInterface {
    distance_to_department_capital: string;
    distance_to_commune_capital: string;
    distance_to_arrondissement_capital: string;
    distance_to_school_district_office: string;
    distance_to_nearest_health_center: string;
    distance_to_nearest_market: string;
    distance_to_farthest_served_village: string;

}

export function Proximity({section, data}: {
    section: string,
    data: SocioEnvironnement
}) {
    const [value,
        setValue] = useSyncReduxCache(data.distances,
        section,
        "detail.data.distances");
    const {validationStatus} = useFormValidator<ProximityInterface>(value, {});
    const locationElement: FormElement[] = [
        {
            id: "distance_to_department_capital",
            title: 'Distance au chef-lieu du département',
            description: "Distance entre l'école et le chef-lieu du département",
            component: <DistanceSelect
                value={value.distance_to_department_capital}
                setValue={(e) => {
                    console.log(value)
                    setValue((prev) => {
                        return {...prev, distance_to_department_capital: e.target.value}
                    })
                }}
            />

        },
        {
            id: "distance_to_commune_capital",
            title: 'Distance au chef-lieu de la commune',
            description: "Distance entre l'école et le chef-lieu de la commune",
            component: <DistanceSelect
                value={value.distance_to_commune_capital}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_commune_capital: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_arrondissement_capital",
            title: "Distance au chef-lieu de l'arrondissement",
            description: "Distance entre l'école et le chef-lieu de l'arrondissement",
            component: <DistanceSelect
                value={value.distance_to_arrondissement_capital}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_arrondissement_capital: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_school_district_office",
            title: 'Distance au bureau de la Circonscription Scolaire',
            description: "Distance entre l'école et le bureau de la circonscription scolaire",
            component: <DistanceSelect
                value={value.distance_to_school_district_office}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_school_district_office: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_nearest_health_center",
            title: 'Distance au centre de santé le plus proche',
            description: "Distance entre l'école et le centre de santé le plus proche",
            component: <DistanceSelect
                value={value.distance_to_nearest_health_center}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_nearest_health_center: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_nearest_market",
            title: 'Distance au marché le plus proche',
            description: "Distance entre l'école et le marché le plus proche",
            component: <DistanceSelect
                value={value.distance_to_nearest_market}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_nearest_market: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_farthest_served_village",
            title: 'Distance au village le plus éloigné desservi',
            description: "Distance entre l'école et le village le plus éloigné desservi",
            component: <DistanceSelect
                value={value.distance_to_farthest_served_village}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_farthest_served_village: e.target.value}
                    })
                }}
            />
        },
    ]
    return <FormComponent
        id={"school_proximity"}
                status={validationStatus.status}

        isCollapsable={true} title={"Distance de l'école"}>
        {
            locationElement.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text
                        id={item.id}
                        data-description={item.description}
                        w={"300px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </FormComponent>

}
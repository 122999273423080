import Card from "../card/Card";
import {Flex, useColorModeValue} from "@chakra-ui/react";
import {Highlight} from "react-instantsearch";
import React from "react";
import ScrollLink from "../ScrollLink";

export const Hit = ({hit, onClose}: {
    onClose: () => void;
    hit: {
        title: string;
        description: string;
        url: string
    };
}) => {
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');

    function removeBaseUrl(url: string) {
        const baseUrl = "https://www.projetses.com/formulaire/"
        return url.replace(baseUrl, "")
    }

    return (
        <Card
            bg={inputBg}
            my={"10px"}

            py='15px' w={"98%"}>
            <ScrollLink
                onClick={onClose}
                to={removeBaseUrl(hit.url)}>

                <Flex flexDirection={"column"} my='auto' h='100%' align={{base: 'center', xl: 'start'}}
                      justify={{base: 'center', xl: 'center'}}>

                    <b><Highlight attribute="title"
                        // @ts-ignore
                                  hit={hit}/></b>
                    <Highlight attribute="description"
                        // @ts-ignore
                                  hit={hit}/>

                </Flex>
            </ScrollLink>

        </Card>
    );
};
/* eslint-disable */
// Chakra Imports
import {Box, Flex, Link, Text, useColorModeValue} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';

export default function AdminNavbar(props: {
    secondary: boolean;
    message: string | boolean;
    activeRoute: RoutesType

    logoText: string;
    fixed: boolean;
    onOpen: (...args: any[]) => any;
}) {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeNavbar);

        return () => {
            window.removeEventListener('scroll', changeNavbar);
        };
    });

    const {secondary, activeRoute} = props;

    // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
    const mainText = 'brand.900'
    const navbarPosition = 'fixed' as const;
    const navbarFilter = 'none';
    const navbarBackdrop = 'blur(20px)';
    const navbarShadow = 'none';
    const navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
    const navbarBorder = 'transparent';
    const secondaryMargin = '0px';
    const paddingX = '15px';
    const gap = '0px';
    const viewportRef = '100vw';

    const changeNavbar = () => {
        if (window.scrollY > 1) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    return (

        <Box

            position={navbarPosition}
            boxShadow={navbarShadow}
            borderColor={navbarBorder}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            backgroundPosition='center'
            backgroundSize='cover'
            borderRadius='16px'
            borderWidth='1.5px'
            borderStyle='solid'
            transitionDelay='0s, 0s, 0s, 0s'
            transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
            transition-property='box-shadow, background-color, filter, border'
            transitionTimingFunction='linear, linear, linear, linear'
            alignItems={{xl: 'center'}}
            display={secondary ? 'block' : 'flex'}
            minH='75px'
            justifyContent={{xl: 'center'}}
            lineHeight='25.6px'
            right={{base: '12px', md: '30px', lg: '30px', xl: '30px'}}

                                                mx={{base: '30px', lg: '180px'}}

            mt={secondaryMargin}
            pb='8px'

            ps={{
                xl: '12px'
            }}
            pt='8px'
            top={{base: '12px', md: '16px', xl: '18px'}}
            w={'fit-content'}>
            <Flex

                w='100%'
                flexDirection={'column'}
                alignItems={'center'}
                mb={gap}>
                <Box textAlign={"center"} w={{base: '100%', md: "100%"}} mb={{sm: '8px', md: '0px'}}>

                    {/* Here we create navbar brand, based on route name */}
                    <Text
                        id={activeRoute.identifier}
                        data-description={activeRoute.description}
                        color={mainText}
                        bg='inherit'
                        borderRadius='inherit'
                        className={"section_title"}
                        fontWeight='bold'
                        fontSize='20px'
                        _hover={{color: {mainText}}}
                        _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent'
                        }}
                        _focus={{
                            boxShadow: 'none'
                        }}>
                        {activeRoute.name}
                    </Text>
                </Box>
                <Box mt={3}>
                    <AdminNavbarLinks
                        secondary={props.secondary}
                    />
                </Box>
            </Flex>
        </Box>


    );
}

import {
    Checkbox,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    Button, IconButton, useColorModeValue, RadioGroup, Stack, Radio,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {ChevronDownIcon} from "@chakra-ui/icons";


function ClassSelector({options,  value, setValue, ...rest}: {
    options: string[];
    value: string;
    setValue: (values: string) => void;
    [x: string]: any;
}) {


    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});



    return (<>
            <Menu closeOnSelect={false} {...rest}>

                <MenuButton
                    alignItems='center'
                    justifyContent='center'


                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    {...rest}
                    as={Button} rightIcon={<ChevronDownIcon/>}>
                    {value}
                </MenuButton>

                <MenuList>

                    <MenuItem>
                        <RadioGroup w={'100%'} onChange={setValue} value={value}>
                            <Stack direction='column'>
                                {options.map((item) => (
                                    <Radio

                                        key={item} value={item}>
                                        {item}
                                    </Radio>
                                ))}

                            </Stack>
                        </RadioGroup>

                    </MenuItem>


                </MenuList>
            </Menu>
        </>
    );
}

export default ClassSelector;

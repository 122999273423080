import React from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Button
} from "@chakra-ui/react";

export function ChakraAlerteDialog({isOpen, onClose, title, message, onAccept, cancelText, acceptText}: {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
    cancelText?: string;
    acceptText?: string;
    onAccept?: () => void;
}) {
    const cancelRef = React.useRef()

    return (

        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay bg='none'

                                backdropFilter='blur(10px)'>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {message}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {cancelText || 'Annuler'}
                        </Button>
                        <Button colorScheme='red' onClick={onAccept} ml={3}>
                            {acceptText || 'Confirmer'}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>

    )
}
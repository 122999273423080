// Chakra imports
import {Box, Button, Flex, Icon, Text, useColorModeValue, useDisclosure} from '@chakra-ui/react';
// Custom components
import Card from '../card/Card';

// Assets
import React from "react";
import Help from "../help/Help";
import {
    IoIosArrowDown,
    IoIosArrowUp,
    IoIosCheckmarkCircle,
    IoIosCloseCircleOutline,
    IoIosHelpCircleOutline, IoIosWarning
} from "react-icons/io";
import ConditionalCollapse from "../ConditionalCollapse/ConditionalCollapse";
import ContextButton from "../icons/ContextButton";
import {alertIcon} from "../../variables/variable";
import {ValidationStatus} from "../../variables/enum";
import {useDispatch, useSelector} from "react-redux";
import {collapseBlock, expandBlock} from "../../slices/collapse";



export default function FormComponent(props: {
    title: string,
    id: string,
    children: any,
    helpMessage?: string,
    isCollapsable?: boolean,
    status?: ValidationStatus,

    [x: string]: any
}) {
    let {title, id,formItems, helpMessage, children, isCollapsable, status, ...rest} = props;
    const dispatch = useDispatch();
     const expandedBlocks = useSelector((state: {
        collapse: {
            expandedBlocks: string[];
        };
    }) => state.collapse.expandedBlocks);

    const isExpanded = expandedBlocks.includes(id);
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});
    if (status === undefined) {
        status = ValidationStatus.Valid
    }
    return (
        <Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                <Text
                    fontSize='lg'
                    fontWeight='700'

                >
                    {title}
                </Text>
                <Flex>
                    {isCollapsable &&
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            mr={1}
                            lineHeight='100%'
                            onClick={() => {
                                if (isExpanded) {
                                    dispatch(collapseBlock(id))
                                } else {
                                    dispatch(expandBlock(id))
                                }
                            }}
                            borderRadius='10px'
                        >
                            <Icon
                                as={isExpanded ? IoIosArrowUp : IoIosArrowDown}
                                color={iconColor} w='24px' h='24px'/>
                        </Button>}
                    <ContextButton contextComponent={alertIcon[status].message}
                                   iconColor={alertIcon[status].color}
                                   icon={alertIcon[status].icon} mr={1}/>
                    <Help helpMessage={helpMessage}/>
                </Flex>

            </Flex>

            <Box px='11px' w='100%'>
                <ConditionalCollapse
                    id={id}
                    condition={isCollapsable}
                >
                    {children}
                </ConditionalCollapse>

            </Box>

        </Card>
    );
}

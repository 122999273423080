import {ValidationStatus} from "../variables/enum";
import {isEmpty} from "../utils/utilsFunction";




export default function useFormValidator<T>(
    data: T,
    validators: Validators<T>
) {
    // Validation directement à partir des données, pas besoin de state
    const hasErrorForField = (validationStatus: ValidationResponse, field: keyof T): boolean => {
        return validationStatus.errors
            ? validationStatus.errors.some((error) => error.field === field)
            : false;
    };
    const errors: ValidationError[] = [];
    let validationStatus = {} as ValidationResponse;

    // Vérification des champs spécifiques pour les erreurs
    Object.keys(validators).forEach((field) => {
        const fieldValue = data[field as keyof T];
        const {validator, message} = validators[field as keyof typeof validators];

        if (fieldValue && !validator(fieldValue as string)) {
            errors.push({field, message});
        }
    });
    if (errors.length > 0) { // Si des erreurs sont trouvées, on retourne un objet avec la liste des erreurs
        validationStatus = {
            status: ValidationStatus.Error,
            errors
        };
    } else {
        // Vérification des champs non vides
        const hasNonEmptyFields = Object.values(data).some((value) => !isEmpty(value));
        const allFieldsFilled = Object.values(data).every((value) => !isEmpty(value));
        if (!hasNonEmptyFields) {
            validationStatus = {status: ValidationStatus.Incomplete};
        } else {
            validationStatus = {status: allFieldsFilled ? ValidationStatus.Valid : ValidationStatus.Incomplete};
        }
    }


    // Retourne des props pour chaque champ pour gestion facile du style et de l'événement onChange
    const getFieldProps = (field: keyof T) => ({
        borderColor: hasErrorForField(validationStatus, field) ? "red.300" : "gray.200",
        boxShadow: hasErrorForField(validationStatus, field)
            ? "0 0 5px 2px rgba(255, 0, 0, 0.2)"
            : "none",
    });

    return {
        validationStatus,
        getFieldProps,
    };
}
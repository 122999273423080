
import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import LoginRequired from "./components/security/LoginRequired";

export default function App() {

    return (

        <React.StrictMode>

                <BrowserRouter>
                    <Switch>
                        <Route path={`/auth`} component={AuthLayout}/>
                        <LoginRequired path={`/formulaire`} component={AdminLayout}/>
                        <Redirect from='/' to='/formulaire'/>
                    </Switch>
                </BrowserRouter>
        </React.StrictMode>

    );
}
// Chakra imports
import {Portal, Box, useDisclosure} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import {SidebarContext} from '../../contexts/SidebarContext';
import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import routes from 'routes';
import {useGetCompletionScoreQuery} from "../../services/Schools";
import {Loader} from "../../components/Loader/Loader";
import {useSelector} from "react-redux";
import {getUserInfo} from "../../slices/selector";



// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const school_id = useSelector(getUserInfo).detail.school_id
    const {...rest} = props;
    const {
        isLoading,
    } = useGetCompletionScoreQuery({school_id: school_id});
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/formulaire/full-screen-maps';
    };
    const getActiveRoute = (routes: RoutesType[]): RoutesType => {
        let activeRoute : RoutesType = routes[0];
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i];
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };
    const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: any) => {
            if (route.layout === '/formulaire') {
                return <Route path={route.layout + route.path} component={route.component} key={key}/>;
            } else {
                return null;
            }
        });
    };
    document.documentElement.dir = 'ltr';
    const {onOpen} = useDisclosure();
    return (
        <Box>
            {isLoading ? <Loader/> :
                <SidebarContext.Provider
                    value={{
                        toggleSidebar,
                        setToggleSidebar
                    }}>
                    <Sidebar routes={routes} display='none' {...rest} />
                    <Box
                        float='right'
                        minHeight='100vh'
                        height='100%'
                        overflow='auto'
                        position='relative'
                        maxHeight='100%'
                        w={{base: '100%', xl: 'calc( 100% - 290px )'}}
                        maxWidth={{base: '100%', xl: 'calc( 100% - 290px )'}}
                        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                        transitionDuration='.2s, .2s, .35s'
                        transitionProperty='top, bottom, width'
                        transitionTimingFunction='linear, linear, ease'>
                        <Portal>
                            <Box>
                                <Navbar
                                    onOpen={onOpen}
                                    logoText={'Ministère de l’Éducation Nationale'}
                                    activeRoute={getActiveRoute(routes)}
                                    secondary={getActiveNavbar(routes)}
                                    message={getActiveNavbarText(routes)}
                                    fixed={fixed}
                                    {...rest}
                                />
                            </Box>
                        </Portal>

                        {getRoute() ? (
                            <Box mx='auto' p={{base: '20px', md: '30px'}} pe='20px' minH='100vh' pt='50px'>
                                <Switch>
                                    {getRoutes(routes)}
                                    <Redirect from='/' to='/formulaire/id_and_location'/>
                                </Switch>
                            </Box>
                        ) : null}
                        <Box>
                            <Footer/>
                        </Box>
                    </Box>
                </SidebarContext.Provider>}
        </Box>
    );
}

import edition from "./edition";

import {schoolsApi} from "../services/Schools";
import auth from "./auth";
import {authApi} from "../services/authService";
import collapse from "./collapse";


export default {
    authentication: auth,
    edition: edition,
    collapse: collapse,
    [authApi.reducerPath]: authApi.reducer,
    [schoolsApi.reducerPath]: schoolsApi.reducer,

};
// Chakra imports
import {
    Box, Button,
    Flex,
    Input,

    Text,
} from '@chakra-ui/react';
// Custom components


// Assets
import React from "react";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import {useForm} from "react-hook-form";

export default function OuvrageCreation(props: {
    onClose: () => void,
    title: string,
    setOuvrageList: (prev: { name: string, quantities_by_grade: { [p: string]: number } }[]) => void,
    section: string,
    helpMessage?: string,
    [x: string]: any
}) {
    const {title, onClose, setOuvrageList, helpMessage} = props;
    const classList = ['ci', 'cp', 'ce1', 'ce2', 'cm1', 'cm2'];
    const {
        register,
        handleSubmit
    } = useForm()
    const submitForm = (data: any) => {
        console.log("data", data)
        // @ts-ignore

        setOuvrageList((prev) => {
            return [
                ...prev,
                {
                    "name": data.name,
                    "quantities_by_grade": {
                        "CI": data.CI || null,
                        "CP": data.CP   || null,
                        "CE1": data.CE1 || null,
                        "CE2": data.CE2 || null,
                        "CM1": data.CM1 || null,
                        "CM2": data.CM2 || null
                    }
                }
            ]
        })
        onClose()
    }
    // @ts-ignore
    return (<form onSubmit={handleSubmit(submitForm)}>

            <Card>

                <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                    <Text

                        fontSize='lg'
                        fontWeight='700'

                    >
                        {title}
                    </Text>
                    <Help helpMessage={helpMessage}/>
                </Flex>
                <Box px='11px' w='100%'>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Nom de l'ouvrage
                        </Text>
                        <Input
                            required={true}
                            {...register('name')}
                            width={"300px"}
                            type={"text"}/>
                    </Flex>
                    {classList.map((classe) => {
                        return <Flex w='100%' mb='20px' alignItems={"center"}
                                     justifyContent={"space-between"}>

                            <Text w={"200px"} fontSize='md' textAlign='start'>
                                {classe.toUpperCase()}
                            </Text>
                            <Input


                                {...register(classe.toUpperCase())}
                                width={"300px"}
                                type={"number"}/>
                        </Flex>
                    })}




                </Box>

                <Button type={"submit"} my={"10px"} bgColor={"brand.900"} color={"white"}>Enregistrer</Button>
            </Card>
        </form>

    )
        ;
}

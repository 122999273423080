import React, {useEffect} from "react";
import {NavLink, useHistory} from "react-router-dom";
// Chakra imports
import {
    Alert, AlertIcon,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import {userLogin} from "../../../thunks/login";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import FormBottom from "../../../components/FormBottom/FormBottom";

function SignIn() {
    const textColor = useColorModeValue("brand.900", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const dispatch = useDispatch()
    const {
        loading,
        userToken,
        error,
        success
    } = useSelector((state: { authentication: IAuthState }) => {

        return {
            loading: state.authentication.loading,
            error: state.authentication.error,
            userToken: state.authentication.userToken,
            success: state.authentication.success
        };
    });
    const history = useHistory()
    const {register, handleSubmit} = useForm()

    useEffect(() => {
        if (userToken) {
            history.push('/formulaire')
        }
    }, [history, userToken])
    const submitForm = (data: LoginRequest) => {
        // @ts-ignore
        dispatch(userLogin(data))
    }
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{base: "100%", md: "max-content"}}
                w='100%'
                mx={{base: "auto", lg: "0px"}}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{base: "30px", md: "60px"}}
                px={{base: "25px", md: "0px"}}
                mt={{base: "40px", md: "14vh"}}
                flexDirection='column'>
                <Box me='auto'>
                    <Heading textAlign={"center"} color={textColor} fontSize='36px' mb='10px'>
                        Connexion
                    </Heading>

                    <Alert
                        my='36px'
                        ms='4px'
                        w={{base: "100%", md: "420px"}}
                        borderRadius={"lg"}

                        status='info' variant='subtle'>
                        <AlertIcon/>
                        Veuillez entrer le code de l'école pour vous connecter
                    </Alert>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{base: "100%", md: "420px"}}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{base: "auto", lg: "unset"}}
                    me='auto'
                    mb={{base: "20px", md: "auto"}}>
                    {/** @ts-ignore */}
                    <form onSubmit={handleSubmit(submitForm)}>

                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Code Ecole<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{base: "0px", md: "0px"}}
                                type='text'
                                placeholder='Code Ecole'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                {...register('school_code')}
                            />

                            <Flex justifyContent='space-between' align='center' mb='24px'>
                                <FormControl display='flex' alignItems='center'>
                                    <Checkbox
                                        id='remember-login'
                                        colorScheme='brandScheme'
                                        me='10px'
                                    />
                                    <FormLabel
                                        htmlFor='remember-login'
                                        mb='0'
                                        fontWeight='normal'
                                        color={textColor}
                                        fontSize='sm'>
                                        Me garder connecté
                                    </FormLabel>
                                </FormControl>
                                <NavLink to='#'>
                                    <Text
                                        color={textColorBrand}
                                        fontSize='sm'
                                        w='124px'
                                        fontWeight='500'>
                                        Code Oublié
                                    </Text>
                                </NavLink>
                            </Flex>
                            <FormBottom errorMessage={error}
                                         successMessage={null}
                                         mainButtonMessage={"Connexion"}
                                         isLoading={loading}
                             />
                        </FormControl>
                    </form>

                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;

import {useHits, UseHitsProps} from 'react-instantsearch';
import React from "react";
import {Flex} from "@chakra-ui/react";
import {Hit} from "./Hit";
import SmoothBox from "../SmoothBox/SmoothBox";

export default function ChakraHits(props: any) {
    const {items, results, banner, sendEvent} = useHits(props);
    const {onClose} = props;
    return <Flex maxH={"400px"}
                 scrollBehavior={"smooth"}
                 sx={{
                     /* Styles pour la barre de défilement */
                     "&::-webkit-scrollbar": {
                         width: "5px",
                         background: "transparent",
                         borderRadius: "15px"
                     },
                     "&::-webkit-scrollbar-thumb": {
                         backgroundColor: "gray.500", // Couleur de la barre de défilement
                         borderRadius: "4px",
                         height: '2px',
                     },
                     "&::-webkit-scrollbar-thumb:hover": {
                         backgroundColor: "gray.700", // Couleur de la barre au survol
                     },
                     "&::-webkit-scrollbar-track": {
                         borderRadius: "20px",
                         height: '2px',
                         background: 'transparent'
                     },
                 }}

                 overflow={"auto"} flexDirection={"column"}>
        {items.length > 0 ?
            items.map((hit) => (
                <Hit
                    onClose={onClose}
                    // @ts-ignore

                    hit={hit}/>
            )) : <SmoothBox>
                Aucun résultat
            </SmoothBox>

        }

    </Flex>
}
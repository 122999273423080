import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {expandBlock} from "../slices/collapse";
import {resetEdition} from "../thunks/resetEdition";

const ScrollLink = ({to, onClick, children}: {
    onClick?: (e: any) => void;
    to: string;
    children: any;
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const editionStatus = useSelector((state: any) => state.edition);
    const handleClick = (e: any) => {
        // Vérifie si targetId contient un hash
        const [path, hash] = to.split("#");

        // Rechercher l'élément cible

        if (hash) {
            e.preventDefault(); // Empêche la navigation par défaut

            // Effectue la navigation vers le chemin sans hash
            history.push(path);

            // Rechercher l'élément cible après navigation
            const checkElementLoaded = setInterval(() => {
                const element = document.getElementById(hash);
                if (element) {
                    // Trouver le bloc collapsable parent
                    const collapsableParent = element.closest(".collapsable");
                    if (collapsableParent && collapsableParent.id) {
                        // Déplie le bloc collapsable parent
                        dispatch(expandBlock(collapsableParent.id));
                    }

                    // Scroll vers l'élément après un léger délai pour permettre le dépliage
                    setTimeout(() => {
                        element.scrollIntoView({behavior: "smooth", block: "center"});
                        element.classList.add("highlight");

                        // Retirer la classe après 3 secondes
                        setTimeout(() => {
                            element.classList.remove("highlight");
                        }, 3000);
                    }, 500);

                    // Arrête la vérification une fois l'élément trouvé
                    clearInterval(checkElementLoaded);
                }
            }, 100); // Vérifie toutes les 100ms
        } else {
            // Si aucun hash n'est présent, effectue une navigation normale
            history.push(to);
        }
    };

    return <>

        <Link to={to} onClick={(e) => {
            const currentSection = history.location.pathname.split("/").pop()
            const toSection = to.split("#")[0]

            if (editionStatus.editionInProgress && currentSection !== toSection) {
                // @ts-ignore
                dispatch(resetEdition())
            }
            if (onClick) {
                onClick(e);
            }
            handleClick(e);

        }
        }>{children}</Link>
    </>
};

export default ScrollLink;
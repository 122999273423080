// Chakra imports
import {Flex, useColorModeValue} from '@chakra-ui/react';


export function SidebarBrand() {
    //   Chakra color mode
    const urlLogo = "https://bucket.mailersendapp.com/0p7kx4xmrvl9yjre/ynrw7gyvj5og2k8e/images/9c69bea8-f588-4e64-956c-2a32a1e3369b.jpg"
    return (
        <Flex alignItems='center' flexDirection='column'>
            <img src={urlLogo} width={200} alt={""}/>
        </Flex>
    );
}

export default SidebarBrand;

import {Collapse, Flex, Input, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";

type AccessibilityInterface = {
    permanent_access: boolean;
    favorable_season_access: string;
    paved_road_distance: number;
    all_vehicle_track_distance: number;
    offroad_vehicle_track_distance: number;
    two_wheeler_track_distance: number;
    pedestrian_track_distance: number;
    other_access_means: string;

}

export function Accessibility({section, data}: {
    section: string,
    data: SocioEnvironnement
}) {
    const [value,
        setValue] = useSyncReduxCache(data.accessibility,
        section,
        "detail.data.accessibility");
    const {validationStatus} = useFormValidator<AccessibilityInterface>(value, {});

    const accessibiliteRoute: FormElement[] = [
        {
            id: "permanent_access",
            description: "Spécifie si l'accès à l'école est permanent ou non durant l'année.",
            title: "L'accès est-il permanent ?",
            component: <Switch isChecked={value.permanent_access} onChange={() => {
                setValue(prevState => ({...prevState, permanent_access: !prevState.permanent_access}))
            }}/>,
        },

        {
            id: "favorable_season_access",
            description: "Précise la période favorable d'accès à l'école. (en mois)",
            title: "Précisez la période favorable d'accès",
            component: <Input
                width={"300px"}
                value={value.favorable_season_access}
                onChange={(e) => {
                    setValue(prevState => ({...prevState, favorable_season_access: e.target.value}))
                }}
                type={"text"}/>,
        },
        {
            id: "paved_road_distance",
            description: "Précise la distance de la route bitumée.",
            title: "Route bitumée (en km)",
            component: <Input width={"300px"}
                              value={value.paved_road_distance}
                              onChange={(e) => {
                                  setValue(prevState => ({...prevState, paved_road_distance: parseInt(e.target.value)}))
                              }}
                              type={"number"}/>,
        },
        {
            id: "all_vehicle_track_distance",
            description: "Précise la distance de la piste accessible à tous véhicules.",
            title: "Piste accessible à tous véhicules (en km)",
            component: <Input width={"300px"}
                              type={"number"}
                              value={value.all_vehicle_track_distance}
                              onChange={(e) => {
                                  setValue(prevState => ({
                                      ...prevState,
                                      all_vehicle_track_distance: parseInt(e.target.value)
                                  }))
                              }}
            />,
        },
        {
            id: "offroad_vehicle_track_distance",
            description: "Précise la distance de la piste accessible seulement aux véhicules tout terrain (4x4).",
            title: "Piste accessible seulement aux véhicules tout terrain (4x4) (en km)",
            component: <Input width={"300px"}
                              value={value.offroad_vehicle_track_distance}
                              onChange={(e) => {
                                  setValue(prevState => ({
                                      ...prevState,
                                      offroad_vehicle_track_distance: parseInt(e.target.value)
                                  }))
                              }}
                              type={"number"}/>,
        },
        {
            id: "two_wheeler_track_distance",
            description: "Précise la distance de la piste accessible seulement aux 2 roues.",
            title: "Piste accessible seulement aux 2 roues (en km)",
            component: <Input width={"300px"}
                              value={value.two_wheeler_track_distance}
                              onChange={(e) => {
                                  setValue(prevState => ({
                                      ...prevState,
                                      two_wheeler_track_distance: parseInt(e.target.value)
                                  }))
                              }}
                              type={"number"}/>,
        },
        {
            id: "pedestrian_track_distance",
            description: "Précise la distance de la piste accessible seulement à pieds.",
            title: "Piste accessible seulement à pieds (en km)",
            component: <Input width={"300px"}
                              value={value.pedestrian_track_distance}
                              onChange={(e) => {
                                  setValue(prevState => ({
                                      ...prevState,
                                      pedestrian_track_distance: parseInt(e.target.value)
                                  }))
                              }}
                              type={"number"}/>,
        },
        {
            id: "other_access_means",
            description: "Précise les autres moyens d'accès à l'école.",
            title: "Autres moyens d'accès (précisez)",
            component: <Input width={"300px"}
                              value={value.other_access_means}
                              onChange={(e) => {
                                  setValue(prevState => ({...prevState, other_access_means: e.target.value}))
                              }}
                              type={"text"}/>,
        },
    ]
    return <FormComponent
        id={"school_accessibility"}
        isCollapsable={true}
        status={validationStatus.status}
        title={"Accesibilité de l'école"}>
        {
            accessibiliteRoute.map((item, index) => (
                item.title === "Précisez la période favorable d'accès" ?
                    <Collapse in={value.permanent_access} animateOpacity key={index}>

                        <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                              justifyContent={"space-between"}>

                            <Text w={"200px"}
                                   id={item.id}
                                data-description={item.description}
                                  fontSize='md' textAlign='start'>
                                {item.title}
                            </Text>
                            {item.component}
                        </Flex>
                    </Collapse> : <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                                        justifyContent={"space-between"}>

                        <Text w={"200px"}
                               id={item.id}
                                data-description={item.description}
                              fontSize='md' textAlign='start'>
                            {item.title}
                        </Text>
                        {item.component}
                    </Flex>
            ))
        }
    </FormComponent>

}
import * as React from "react";

import {TableData} from "../../../../components/dataTable/TableData";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import AddButton from "../../../../components/AddButton/AddButton";
import {useDisclosure} from "@chakra-ui/react";
import ChakraModal from "../../../../components/Modal/ChakraModal";
import OuvrageCreation from "./OuvrageCreation";

export function OuvragePedagogique({id, section, data, path, title, desc,extendant, key_el}: {
    section: string,
    path: string,
    title: string,
    id: string,
    desc: string,
    key_el: "teacher_pedagogical_materials" | "student_pedagogical_materials",
    extendant?: boolean,
    data:  TeachingAndLearningEquipment
}) {
    const {isOpen, onOpen, onClose} = useDisclosure()

    const [value,
        setValue] = useSyncReduxCache(
        data[key_el].materials,
        section,
        path);
    const classList = ['ci', 'cp', 'ce1', 'ce2', 'cm1', 'cm2'];

    const columns: DataColumn[] = [
        {
            header: "Nom",
            accessor: "name",
            kind: "text",
            isEditable: false,
            width: '40%'
        },
        ...classList.map((classe) => {
            return {
                accessor: classe.toUpperCase(),
                header: classe.toUpperCase(),
                isEditable: true,
                kind: 'number',
                width: '10%'
            } as DataColumn
        })
    ]

    const educationalBooksData: NumberByClass[] = value.map((eqt) => {
        return {
            name: eqt.name,
            CI: eqt.quantities_by_grade.CI,
            CP: eqt.quantities_by_grade.CP,
            CE1: eqt.quantities_by_grade.CE1,
            CE2: eqt.quantities_by_grade.CE2,
            CM1: eqt.quantities_by_grade.CM1,
            CM2: eqt.quantities_by_grade.CM2
        }
    })
    return <>
        {extendant &&
           <ChakraModal isOpen={isOpen} onClose={onClose}>

                <OuvrageCreation
                    onClose={onClose}
                    section={section}
                    setOuvrageList={setValue}
                    helpMessage={"Ajouter un personnel"}
                    title={"Ajouter un personnel"}
                />
            </ChakraModal>}
        <TableData<NumberByClass>
            isCollapsable={true}
        id={id}
        columns={columns}
        setValue={(value: NumberByClass[]) => {
            setValue(value.map((eqt) => {
                return {
                    name: eqt.name,
                    quantities_by_grade: {
                        CI: eqt.CI,
                        CP: eqt.CP,
                        CE1: eqt.CE1,
                        CE2: eqt.CE2,
                        CM1: eqt.CM1,
                        CM2: eqt.CM2
                    }
                }
            }))
        }}


        data={educationalBooksData}

        helperComponent={extendant && <AddButton onClick={onOpen} message={"Ajouter un ouvrage"}/>}

        title={title}
        helpMessage={desc}/>
    </>
}
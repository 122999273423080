import React from 'react';

// Chakra imports
import {
    Icon,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useColorModeValue,
    Box, Button
} from '@chakra-ui/react';
// Assets
import {
    MdOutlineMoreHoriz,
    MdOutlinePerson,
    MdOutlineCardTravel,
    MdOutlineLightbulb,
    MdOutlineSettings
} from 'react-icons/md';
import {IoIosHelpCircleOutline} from "react-icons/io";

export default function AddButton({message, ...rest}: {
    message: string
    [x: string]: any
}) {




    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    // Ellipsis modals

    return (
            <Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}

                lineHeight='100%'
                borderRadius='10px'
                {...rest}
                >
                <Text textOverflow={"clip"} w={"100%"}>
                {message}

                </Text>
            </Button>

    );
}

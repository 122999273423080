import {Flex, Icon, Input} from "@chakra-ui/react";
import {PiGenderFemaleBold, PiGenderMaleBold} from "react-icons/pi";
import React from "react";

export default function SizeBySex({value, onChangeMale, onChangeFemale}: {
    value: {
        M: number,
        F: number
    }
    onChangeMale?: (e: any) => void
    onChangeFemale?: (e: any) => void
}) {
    const dimension = {
        width: "65px",
        height: "30px"
    }
    return <>
        <Flex flexDirection={"column"}>
            <Flex alignItems={"center"}>
                <Icon as={PiGenderMaleBold} color={"blue.500"}/>
                <Input type='number'
                       onChange={onChangeMale}
                       value={value.M}
                       h={dimension.height} w={dimension.width} mx='10px'/>
            </Flex>
            <Flex alignItems={"center"} mt={"5px"}>
                <Icon as={PiGenderFemaleBold} color={"red.500"}/>
                <Input type='number'
                       onChange={onChangeFemale}
                          value={value.F}
                       h={dimension.height} w={dimension.width} mx='10px'/>
            </Flex>
        </Flex></>
}
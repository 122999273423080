import * as React from "react";

import {
    Box,
    Button,
    Flex,
    Icon, Input,
    Table,
    Tbody, Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";

import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import {useState} from "react";

function cellEquivalencesName(rowKey: string, field: string): string {
    const cellEquivalences = {
        "Année": {
            'Année de création': 'creation_year',
            'Année de dernière extension': 'extension_year'
        },
        "Numéro de référence": {
            'Année de création': 'creation_reference',
            'Année de dernière extension': 'extension_reference'
        },
        "Année de référence": {
            'Année de création': 'creation_year_reference_date',
            'Année de dernière extension': 'extension_year_reference_date'
        }

    }
    // @ts-ignore
    return cellEquivalences[field][rowKey]
}

function typeConverter(typeName: string, value: string) {
    const typeEquivalences = {
        'creation_year': (value: string) => parseInt(value),
        'extension_year': (value: string) => parseInt(value),
        'creation_reference': (value: string) => value,
        'extension_reference': (value: string) => value,
        'creation_year_reference_date': (value: string) => value,
        'extension_year_reference_date': (value: string) => value
    }
    // @ts-ignore
    return typeEquivalences[typeName](value)
}
export function ReferenceYear({data, section}: {
    data: IdAndLocation | null,
    section: string
}) {
    const [value,
        setValue] = useSyncReduxCache(
        data.reference_year,
        section,
        "detail.data.reference_year");

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const [editingCell, setEditingCell] = useState<{
        rowKey: string
        field: string
    }>({rowKey: null, field: null});
    const handleCellClick = (rowKey: any, field: any) => {

        setEditingCell({rowKey, field});
    };
    const referenceYearData: ReferenceYearType[] = [
        {
            name: 'Année de création',
            year: value.creation_year,
            reference_number: value.creation_reference,
            reference_year: value.creation_year_reference_date
        },
        {
            name: 'Année de dernière extension',
            year: value.extension_year,
            reference_number: value.extension_reference,
            reference_year: value.extension_year_reference_date
        },
    ]


    const handleBlur = (e: { target: { value: any; }; }) => {
        setValue((prev => {
            console.log(prev)

            console.log(editingCell)
            console.log({
               ...prev,
                [editingCell.rowKey]: typeConverter(editingCell.rowKey, e.target.value)
            })
            return {
               ...prev,
                [editingCell.rowKey]: typeConverter(editingCell.rowKey, e.target.value)
            }
        }));
        setEditingCell({rowKey: null, field: null})

    };

    return <Card flexDirection='column' w='100%' px='0px' overflowX={{sm: 'scroll', lg: 'hidden'}}>
        <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
            <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                Année de référence
            </Text>

            <Flex>

                <Help
                    helpMessage={"L'année de référence est l'année de création de l'école et l'année de la dernière extension"}
                />
            </Flex>
        </Flex>

        <Box>

            <Table size='sm' variant='striped' mb='24px' mt="12px">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>Année</Th>
                        <Th>Numéro de référence</Th>
                        <Th>Année de référence</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        referenceYearData.map((row, rowKey) =>
                            <Tr h={"10px"} minH={"1px"} key={rowKey}>

                                <Td w={"20%"}>{row.name}</Td>
                                <Td w={"20%"} onClick={() => handleCellClick(cellEquivalencesName(row.name, "Année"),
                                    "Année")}>
                                    {editingCell.rowKey === cellEquivalencesName(row.name, "Année") && editingCell.field === "Année" ? (
                                        <Input
                                            type={"number"}
                                            max={new Date().getFullYear()}
                                            // @ts-ignore
                                            defaultValue={row.year}

                                            onBlur={handleBlur}
                                            autoFocus
                                        />
                                    ) : (
                                        // @ts-ignore
                                        <span>{row.year}</span>
                                    )}
                                </Td>
                                <Td w={"20%"} onClick={() => handleCellClick(cellEquivalencesName(row.name, "Numéro de référence"), "Numéro de référence")}>
                                    {editingCell.rowKey === cellEquivalencesName(row.name, "Numéro de référence") && editingCell.field === "Numéro de référence" ? (

                                        <Input

                                            type={"text"}
                                            // @ts-ignore
                                            defaultValue={row.reference_number}

                                            onBlur={handleBlur}
                                            autoFocus
                                        />
                                    ) : (
                                        // @ts-ignore
                                        <span>{row.reference_number}</span>
                                    )}
                                </Td>
                                <Td w={"20%"} onClick={() => handleCellClick(cellEquivalencesName(row.name, "Année de référence"), "Année de référence")}>
                                    {editingCell.rowKey === cellEquivalencesName(row.name, "Année de référence") && editingCell.field === "Année de référence" ? (
                                        <Input
                                            type={"date"}
                                            // @ts-ignore
                                            defaultValue={row.reference_year}

                                            onBlur={handleBlur}
                                            autoFocus
                                        />
                                    ) : (
                                        // @ts-ignore
                                        <span>{row.reference_year}</span>
                                    )}
                                </Td>
                            </Tr>
                        )
                    }


                </Tbody>


            </Table>
        </Box>

    </Card>


}
import {
    Box,
    Button,
    Flex,
    Icon,
    Input, Select,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

import React, {ReactNode, useState} from "react";
import Help from "../help/Help";
import ConditionalCollapse from "../ConditionalCollapse/ConditionalCollapse";
import Card from "../card/Card";
import {useDispatch, useSelector} from "react-redux";
import {collapseBlock, expandBlock} from "../../slices/collapse";
import {EditIcon} from "@chakra-ui/icons";

export function EditableCell(props: {
    data: any,
}){
    return  <Box cursor="pointer"

        position="relative">
                            {props.data}
                            <Icon
                                as={EditIcon}
                                boxSize={3}
                                color="gray.500"

                                position="absolute"
                                bottom="0px"
                                right="8px"

                            />
                        </Box>
}

function CellComponent<T>({
                              kind,
                              rowKey,
                              accessor,
                              component,
                              row,
                              options,
                              editingCell,
                              handleCellClick,
                              handleBlur,
                              width,
                              isEditable
                          }: {
    kind: 'text' | 'select' | 'date' | 'number' | 'boolean' | 'action'
    accessor?: string,
    row: T,
    width?: string,
    handleBlur: (e: any) => void,
    editingCell: {
        rowKey: number,
        field: string
    }
    rowKey: number,
    handleCellClick: (rowKey: number, field: string) => void,
    component: (props: any) => ReactNode
    options?: Option[]
    isEditable: boolean
}) {

    if (isEditable) {
        switch (kind) {
            case 'action':
                // @ts-ignore
                return <Td w={width}>{component({row: row})}</Td>
            case 'number':
            case 'date':
            case 'text':
                // @ts-ignore
                return <Td w={width} onClick={() => handleCellClick(rowKey, accessor)}>
                    {editingCell.rowKey === rowKey && editingCell.field === accessor ? (
                        <Input
                            type={kind}
                            // @ts-ignore
                            defaultValue={row[accessor]}

                            onBlur={handleBlur}
                            autoFocus
                        />
                    ) : (
                        // @ts-ignore
                       <EditableCell data={row[accessor]}/>
                    )}
                </Td>
            case "select":
                return <Td w={"20%"} onClick={() => handleCellClick(rowKey, accessor)}>
                    {editingCell.rowKey === rowKey && editingCell.field === accessor ? (
                        // @ts-ignore
                        <Select defaultValue={row[accessor]} onBlur={handleBlur} autoFocus>
                            {options.map((option, key) => <option key={key}
                                                                  value={option.value}>{option.label}</option>)}
                        </Select>
                    ) : (
                        // @ts-ignore
                       <EditableCell data={row[accessor]}/>
                    )}
                </Td>


        }
    } else {
        // @ts-ignore
        return <Td w={width}><span>{row[accessor]}</span></Td>
    }

}

export function TableData<T>({
                                 title,
                                 setValue,
                                 id,
                                 columns,

                                 data, helpMessage, helperComponent,
                                 isCollapsable
                             }: {
    title: string,
    id: string,
    isCollapsable?: boolean,
    columns: DataColumn[],
    setValue: (value: T[]) => void,

    data: T[]
    helpMessage: string,
    helperComponent?: React.ReactNode
}) {
    const dispatch = useDispatch();
    const expandedBlocks = useSelector((state: {
        collapse: {
            expandedBlocks: string[];
        };
    }) => state.collapse.expandedBlocks);

    const isExpanded = expandedBlocks.includes(id);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'})
    const [editingCell, setEditingCell] = useState<{
        rowKey: number
        field: string
    }>({rowKey: null, field: null});
    const handleCellClick = (rowKey: any, field: any) => {

        setEditingCell({rowKey, field});
    };

    // Fonction pour sauvegarder la modification et sortir du mode édition
    const handleBlur = (e: { target: { value: any; }; }) => {
        setValue(data.map((row, key) => {
            if (key === editingCell.rowKey) {
                return {...row, [editingCell.field]: e.target.value};
            }
            return row;
        }));
        setEditingCell({rowKey: null, field: null});

    };
    return (
        <Card flexDirection='column' w='100%' px='0px' overflowX={{sm: 'scroll', lg: 'hidden'}}>
            <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                    {title}
                </Text>
                {helperComponent}
                <Flex>
                    {isCollapsable &&
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            mr={1}
                            lineHeight='100%'
                              onClick={() => {
                                if (isExpanded) {
                                    dispatch(collapseBlock(id))
                                } else {
                                    dispatch(expandBlock(id))
                                }
                            }}
                            borderRadius='10px'
                        >
                            <Icon
                                as={isExpanded ? IoIosArrowUp : IoIosArrowDown}
                                color={iconColor} w='24px' h='24px'/>
                        </Button>}
                    <Help helpMessage={helpMessage}/>
                </Flex>
            </Flex>
            <ConditionalCollapse
                id={id}
                condition={isCollapsable}
            >
                <Box>

                    <Table size='sm' variant='striped' mb='24px' mt="12px">
                        <Thead>
                            <Tr>
                                {
                                    columns.map((column, key) =>
                                        <Th key={key}>{column.header}</Th>
                                    )
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                data.map((row, rowKey) =>
                                    <Tr h={"10px"} minH={"1px"} key={rowKey}>

                                        {
                                            columns.map((column, columnKey) => {

                                                    return <CellComponent<T>
                                                        rowKey={rowKey}
                                                        kind={column.kind}
                                                        accessor={column.accessor}
                                                        row={row}
                                                        width={column.width}
                                                        handleBlur={handleBlur}
                                                        editingCell={editingCell}
                                                        handleCellClick={handleCellClick}
                                                        component={column.component}
                                                        options={column.options}
                                                        isEditable={column.isEditable}
                                                    />
                                                }
                                            )
                                        }

                                    </Tr>
                                )
                            }


                        </Tbody>
                        <Tfoot mt={2}>
                            <Tr>
                                <Th>{data.length} éléments</Th>

                            </Tr>
                        </Tfoot>

                    </Table>
                </Box>
            </ConditionalCollapse>

        </Card>
    )
}
import useSyncReduxCache from "../../../hooks/useSyncReduxCache";
import {Collapse, Flex, Input, Switch, Text} from "@chakra-ui/react";
import SelectCheckbox from "../../../components/SelectCheckBox/SelectCheckBox";
import FormComponent from "../../../components/form/FormComponent";
import React from "react";

export default function FormSection({section, data}: {
    section: string,
    data: ParticipatorySchoolManagement
}) {
    const [value,
        setValue] = useSyncReduxCache(data,
        section,
        "detail.data");
    const otherThemes = [
        "Environnement",
        "Santé",
        "Kit Scolaire",
        "Maintien des enfants à l'école",
    ]
    return <FormComponent
        id={section}
        title={"Gestion Participative de l'école"}>
        <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
            <Text w={"200px"} fontSize='md' textAlign='start'>
                Le bureau de l'APE est t'il fonctionnel ?
            </Text>
            <Switch
                isChecked={value.is_ape_office_functional}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        is_ape_office_functional: !prevState.is_ape_office_functional
                    }))
                }}
                colorScheme='brandScheme' size='md'/>
        </Flex>
        <Collapse in={value.is_ape_office_functional} animateOpacity>
            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Ce bureau comporte t'il tout les membres requis ?
                </Text>
                <Switch
                    isChecked={value.has_all_required_members}
                    onChange={() => {
                        setValue(prevState => ({
                            ...prevState,
                            has_all_required_members: !prevState.has_all_required_members
                        }))
                    }}
                    variant='main' colorScheme='brandScheme' size='md'/>
            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Combien de réunions avez-vous tenu l'année scolaire précédente ?
                </Text>
                <Input
                    value={data.num_meetings_previous_year}
                    type={"number"} width={"300px"}
                    onChange={(e) => {
                        setValue(prevState => ({
                            ...prevState,
                            num_meetings_previous_year: parseInt(e.target.value)
                        }))
                    }}
                />
            </Flex>
            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Combien d'Assemblées Générales (AG) avez-vous tenu l'année scolaire précédente ?
                </Text>
                <Input
                    value={data.num_general_assemblies_previous_year}
                    onChange={(e) => {
                        setValue(prevState => ({
                            ...prevState,
                            num_general_assemblies_previous_year: parseInt(e.target.value)
                        }))
                    }}
                    type={"number"} width={"300px"}/>
            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Combien d'AG ont traité des questions du budget?
                </Text>
                <Input
                    value={data.num_assemblies_budget}
                    onChange={(e) => {
                        setValue(prevState => ({
                            ...prevState,
                            num_assemblies_budget: parseInt(e.target.value)
                        }))
                    }}
                    type={"number"} width={"300px"} disabled={data.num_general_assemblies_previous_year === 0}/>
            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Combien d'AG ont traité des questions des résultats scolaires?
                </Text>
                <Input
                    value={data.num_assemblies_academic_results}
                    onChange={(e) => {
                        setValue(prevState => ({
                            ...prevState,
                            num_assemblies_academic_results: parseInt(e.target.value)
                        }))
                    }}
                    type={"number"} width={"300px"} disabled={data.num_general_assemblies_previous_year === 0}/>
            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Sélectionnez les autres thèmes traités lors des AG
                </Text>
                <SelectCheckbox options={otherThemes}
                                checkedItems={data.other_assembly_themes}
                                onChange={(checkedItems) => {
                                    setValue(prevState => ({
                                        ...prevState, other_assembly_themes: checkedItems
                                    }))
                                }}/>


            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    Mode de communication des résultats
                </Text>
                <SelectCheckbox
                    options={["Carnet de correspondance", "Réunion APE", "AG", "Echange avec les parents concerné"]}
                    checkedItems={data.result_communication_methods}
                    onChange={(checkedItems) => {
                        setValue(prevState => ({
                            ...prevState, result_communication_methods: checkedItems
                        }))
                    }}

                />
            </Flex>

            <Flex w='100%' mb='20px' alignItems={"center"} justifyContent={"space-between"}>
                <Text w={"200px"} fontSize='md' textAlign='start'>
                    L'école dispose-t-elle de projets d'éducation au développement (Ecoles verte) ?
                </Text>
                <Switch
                    isChecked={value.has_development_education_projects}
                    onChange={() => {
                        setValue(prevState => ({
                            ...prevState,
                            has_development_education_projects: !prevState.has_development_education_projects
                        }))
                    }}
                    variant='main' colorScheme='brandScheme' size='md'/>
            </Flex>
        </Collapse>
    </FormComponent>
}

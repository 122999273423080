import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";

export function WaterAndHygiene({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const [value,
        setValue] = useSyncReduxCache(data.water_and_hygiene_access,
        section,
        "detail.data.water_and_hygiene_access");
    const waterAndHygiene: FormElement[] = [
        {
            title: 'L\'école a-t-elle un point d\'eau potable dans le domaine ?',
            component: <Switch
                isChecked={value.has_drinking_water_on_site}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_drinking_water_on_site: !prevState.has_drinking_water_on_site
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },
        {
            title: 'L\'école dispose-t-elle d\'un dispositif de lavage de mains ?',
            component: <Switch
                isChecked={value.has_handwashing_facility}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_handwashing_facility: !prevState.has_handwashing_facility
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école a-t-elle des latrines fonctionnelles ?",
            component: <Switch
                isChecked={value.has_functional_latrines}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_functional_latrines: !prevState.has_functional_latrines
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "Existe-t-il des latrines adaptées aux enfants à besoins spécifiques ?",
            component: <Switch
                isChecked={value.has_adapted_latrines_for_special_needs}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_adapted_latrines_for_special_needs: !prevState.has_adapted_latrines_for_special_needs
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: " L'école a-t-elle un urinoir aménagé ?",
            component: <Switch
                isChecked={value.has_equipped_urinal}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_equipped_urinal: !prevState.has_equipped_urinal
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },

    ]
    return <>
        {
            waterAndHygiene.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </>

}
import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";

interface PropertyAndDeliInterface {
    school_property_title: string[];
    is_school_area_demarcated: boolean;
    is_school_area_fenced: boolean;

}

export function PropertyAndDeli({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const propertyTitle = [
        "Titre foncier",
        "Acte de donation",
        "Acte de l'autorité chargée de l'administration territoriale",
        "Contrat de location entre l'école et un propriétaire",
    ];
    const [value,
        setValue] = useSyncReduxCache(data.property_and_boundaries,
        section,
        "detail.data.property_and_boundaries");
    const {validationStatus} = useFormValidator<PropertyAndDeliInterface>(value, {});

    const propertyAndDeli: FormElement[] = [
        {
            title: 'Titre de propriété de l\'école ',
            id: 'school_property_title',
            description: "Contient les titres de propriété de l'école, le titre de propriété est un document qui atteste de la propriété d'un bien immobilier. Il est délivré par un notaire ou un avocat.",
            component: <SelectCheckbox
                options={propertyTitle}
                checkedItems={value.school_property_title}
                onChange={(checkedItems) => {
                    setValue(prevState => ({
                        ...prevState, school_property_title: checkedItems
                    }))
                }}
            />
        },
        {
            title: 'Le domaine scolaire est-il délimité?',
            id: 'is_school_area_demarcated',
            description: "Spécifie si le domaine scolaire est délimité ou non.",
            component: <Switch
                isChecked={value.is_school_area_demarcated}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        is_school_area_demarcated: !prevState.is_school_area_demarcated
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: 'Le domaine scolaire est-il limité par une clôture ?',
            id: 'is_school_area_fenced',
            description: "Spécifie si le domaine scolaire est limité par une clôture ou non.",
            component: <Switch
                isChecked={value.is_school_area_fenced}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        is_school_area_fenced: !prevState.is_school_area_fenced
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },

    ]
    return <FormComponent
        id={"property_and_boundaries"}
         status={validationStatus.status}
                        isCollapsable={true}
                        title={"Propriété et délimitations"}>
        {
            propertyAndDeli.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text
                           id={item.id}
                                data-description={item.description}
                        w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </FormComponent>

}
import {useState, useEffect} from "react";
import {Box, Input, Tag, TagLabel, TagCloseButton, Wrap} from "@chakra-ui/react";

interface PhoneInputProps {
    value?: string[];
    onChange?: (phones: string[]) => void;

    [key: string]: any; // Pour permettre les autres props de style
}

function PhoneInput({value = [], onChange, ...props}: PhoneInputProps) {
    const [phones, setPhones] = useState<string[]>(value);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setPhones(value);
    }, [value]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue.trim()) {
            e.preventDefault();
            const newPhones = [...phones, inputValue.trim()];
            setPhones(newPhones);
            setInputValue("");
            onChange && onChange(newPhones); // Appeler onChange si défini
        }
    };

    const handleRemovePhone = (phone: string) => {
        const newPhones = phones.filter(p => p !== phone);
        setPhones(newPhones);
        onChange && onChange(newPhones); // Appeler onChange si défini
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        // Autorise uniquement les chiffres
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };

    return (
        <Box
            width="300px"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            padding="2"
            {...props} // Passer les autres props de style ici
        >
            <Wrap spacing={2}>
                {phones.map((phone, index) => (
                    <Tag key={index} borderRadius="full" variant="solid" colorScheme="blue">
                        <TagLabel>{phone}</TagLabel>
                        <TagCloseButton onClick={() => handleRemovePhone(phone)}/>
                    </Tag>
                ))}
                <Input
                    type="tel"
                    value={inputValue}
                    onChange={handleInputChange }
                    onKeyDown={handleKeyDown}
                    variant="unstyled"
                    placeholder={phones.length > 0 ? "" : "Entrez un numéro"}
                    width="auto"
                    flex="1"
                />
            </Wrap>
        </Box>
    );
}

export default PhoneInput;

import {StudentRecordTable} from "./StudentRecordTable";
import React from "react";

export function StudentByRedoublant({section, data}: {
    section: string,
    data: StudentsRecord,

}) {
    return <StudentRecordTable
        id={'by_redoublant'}
        section={section}
        subsection_attribute={'status'}
        subsection={'repeaters_by_age'}
        title={'Effectif des élèves redoublants'}
        helpMessage={"Répartition des élèves redoublants"}
        data={data}
    />
}
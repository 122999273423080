import {Box} from "@chakra-ui/react";
import React from "react";

import {FinancialTable} from "./FinanceTable";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";


export function FinancialData() {
    const section_name = 'financial_data'
    const {section_data: financial_data, isLoading} = useGetSectionData<FinancialData>(section_name);
    return <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
        {isLoading ? <Loader/> :
            <FinancialTable data={financial_data} section={section_name}/>
        }
    </Box>
}
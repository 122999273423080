import React from 'react';

// Chakra imports
import {
    Icon,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useColorModeValue,
    Box
} from '@chakra-ui/react';

export default function ContextButton({icon, iconColor, contextComponent, ...rest}: {
    icon: any
    iconColor: string
    contextComponent: React.ReactNode
    [x: string]: any
}) {


    const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    // Ellipsis modals
    const {isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1} = useDisclosure();


    return (
        <Menu isOpen={isOpen1} onClose={onClose1}
            // @ts-ignore
              ms={"auto"}>
            <MenuButton
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                onClick={onOpen1}
                borderRadius='10px'
                {...rest}>
                <Icon as={icon} color={iconColor} w='24px' h='24px'/>
            </MenuButton>
            <MenuList
                w='300px'
                minW='unset'
                maxW='300px !important'
                border='transparent'
                backdropFilter='blur(63px)'
                bg={'blur(63px)'}
                boxShadow={bgShadow}
                borderRadius='20px'
                p='15px'>

                <Box bg={'blur(63px)'}
                     w='100%' color='black' borderRadius='md'>
                    {contextComponent}
                </Box>
            </MenuList>
        </Menu>
    );
}

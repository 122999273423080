import {TagDescription} from "@reduxjs/toolkit/query";
import {schoolsApi} from "../services/Schools";
import {getUserInfo} from "../slices/selector";

export const resetEdition = () => (dispatch: (arg0: {
    type: string;
    payload?: TagDescription<"schools" | "SchoolSection">[];
}) => void, getState: () => { (): any; new(): any; edition: { (): any; new(): any; editedSection: any; }; }) => {
     // Récupère le school_id depuis le sélecteur
    const school_id = getUserInfo(getState()).detail.school_id;
    const section = getState().edition.editedSection;
    // Invalide la requête associée à school_id et section
    dispatch(
        schoolsApi.util.invalidateTags([
            {type: 'SchoolSection', id: `${school_id}-${section}`}
        ])
    );
    // Appelle l'action resetEdition pour modifier l'état local
    dispatch({type: 'edition/endEdition'});
};

import * as React from "react";
import {Collapse} from "@chakra-ui/react";
import {useSelector} from "react-redux";

export default function ConditionalCollapse({
                                                id,
                                                condition,
                                                children
                                            }: {
    id: string;
    condition: boolean;
    children: React.ReactNode;
}) {
    const expandedBlocks = useSelector((state: {
        collapse: {
            expandedBlocks: string[];
        };
    }) => state.collapse.expandedBlocks);

    const isExpanded = expandedBlocks.includes(id);


    return condition ? <Collapse
        id={id}
        className={"collapsable"}
        in={isExpanded}
        animateOpacity
    >{children}</Collapse> : <>{children}</>
}
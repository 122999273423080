import React from "react";
import {
    Box,
    Flex,
    SimpleGrid,

    Text,

    useColorModeValue
} from "@chakra-ui/react";
import Help from "../../../components/help/Help";
import Card from "../../../components/card/Card";
import SizeBySex from "../../../components/SizeBySex/SizeBySex";
import {TableExam} from "./TableExam";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";

export function ExamResult() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const section_name = 'exam_results'
    const {section_data: exam_results, isLoading} = useGetSectionData<ExamResults>(section_name);

    return (
        <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
            <SimpleGrid columns={1} gap='20px' mb='20px'>
                <Card flexDirection='column' w='100%' px='0px' overflowX={{sm: 'scroll', lg: 'hidden'}}>
                    <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                            Résultats aux examens de la dernière session
                        </Text>

                        <Help helpMessage={"Ajout d'élève"}/>
                    </Flex>
                    <Box>
                        <TableExam
                            section={section_name}
                            data={exam_results}
                        />
                    </Box>
                </Card>
            </SimpleGrid>}
        </Box>

    )
}
import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";

export function SportFacilities({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const [value,
        setValue] = useSyncReduxCache(data.sports_facilities,
        section,
        "detail.data.sports_facilities");
    const sportFacilities: FormElement[] = [
        {
            title: "L'école a-t-elle un terrain de sport tracé ?",
            component: <Switch
                isChecked={value.has_marked_sports_field}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_marked_sports_field: !prevState.has_marked_sports_field
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école a-t-elle un terrain de sport équipé ?",
            component: <Switch
                isChecked={value.has_equipped_sports_field}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_equipped_sports_field: !prevState.has_equipped_sports_field
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }
    ]
    return <>
        {
            sportFacilities.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </>

}
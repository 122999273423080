import {StudentRecordTable} from "./StudentRecordTable";
import React from "react";

export function StudentByYear({section, data}: {
    section: string,
    data: StudentsRecord,

}) {
    return <StudentRecordTable
        id={'by_year'}
        section={section}
        subsection_attribute={'years'}
        subsection={'student_enrollment_by_age'}
        title={'Effectif des élèves par age'}
        helpMessage={"Répartition des élèves par age"}
        data={data}
    />
}
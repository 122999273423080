import * as React from "react";
import {Loader} from "../../../../components/Loader/Loader";
import Checklist from "../../../../components/checklist/Checklist";



export function StatusAndType({data, loading, section}: {
    data: IdAndLocation | null,
    loading: boolean
    section: string
}) {
    const render = () => {
        if (loading) {
            return <Loader/>
        } else {
            const schoolStatus = ['Public', 'Privé Laïc', 'Privé confessionnel', 'Communautaire']
            const schoolType = ['Classique', 'Inclusif', 'Spécialisé', 'Autres']
            return <>
                <Checklist dataPath={"detail.data.school_status"} section={section} selectedItems={data.school_status} type={"radio"} name={"Statut"} listItems={schoolStatus}
                                help={"Correspond au status de l'école"}/>
                <Checklist dataPath={"detail.data.school_kind"} section={section} selectedItems={data.school_kind} type={"radio"} name={"Type"}
                           help={"Correspond au type de l'école"}
                           listItems={schoolType}/>
            </>

        }
    }

    return render()
}
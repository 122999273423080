import {StudentRecordTable} from "./StudentRecordTable";
import React from "react";

export function StudentParticularCase({section, data}: {
    section: string,
    data: StudentsRecord,

}) {
    return <StudentRecordTable
        id={'particular_case'}
        section={section}
        subsection_attribute={'situation'}
        subsection={'particular_situation_by_age'}
        title={'Cas particuliers'}
        helpMessage={"Répartition des cas particuliers"}
        data={data}
    />
}
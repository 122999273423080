import {Box,  SimpleGrid} from "@chakra-ui/react";
import React from "react";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";
import FormSection from "./form";

export function ParticipatorySchoolManagement() {
    const section_name = 'participatory_school_management'
    const {
        section_data: participatory_school_management,
        isLoading
    } = useGetSectionData<ParticipatorySchoolManagement>(section_name);



    return <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>

        <SimpleGrid columns={1} gap='20px' mb='20px'>
            {isLoading ? <Loader/> :
                <FormSection section={section_name} data={participatory_school_management}/> }
        </SimpleGrid>

    </Box>
}
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEqual} from "lodash"; // Pour comparer les objets de manière approfondie
import {schoolsApi} from "../services/Schools";
import {updateObjectByPath} from "../utils/utilsFunction";
import {getUserInfo} from "../slices/selector"; // Assurez-vous que le chemin est correct

// Hook personnalisé
function useSyncReduxCache<T>(
    initialValue: T,     // La valeur initiale à surveiller
    section: string,     // Nom de la section dans laquelle se trouve la valeur
    dataPath: string     // Chemin de la variable dans l'objet du cache
) {
    const dispatch = useDispatch();
    const school_id = useSelector(getUserInfo).detail.school_id

    // État local pour la valeur surveillée
    const [value, setValue] = useState<T>(initialValue);

    useEffect(() => {
        // Si la valeur a changé (comparaison profonde si nécessaire)

        if (!isEqual(initialValue, value)) {
            // Mettre à jour le cache Redux Toolkit
            dispatch(
                // @ts-ignore
                schoolsApi.util.updateQueryData(
                    'getPartialSchool', // Assurez-vous que c'est bien le nom de la requête
                    {school_id: school_id, section: section},
                    (draft: any) => {
                        // Mise à jour avec la nouvelle valeur à la position dataPath
                        updateObjectByPath(draft, dataPath, value);
                    }
                )
            );
            // Si nécessaire, lancer une action Redux supplémentaire
            dispatch({type: 'edition/startEdition', payload: {section}});
        }
    }, [initialValue, value, section, dataPath, dispatch, school_id]); // Dépendances du hook

    // Retourner la valeur et le setter pour pouvoir manipuler la valeur en dehors du hook
    return [value, setValue] as const;
}

export default useSyncReduxCache;

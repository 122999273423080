import {useGetPartialSchoolQuery} from "../services/Schools";
import {getUserInfo} from "../slices/selector";
import {useSelector} from "react-redux";


export default function useGetSectionData<T>(section_name: string) {
    const school_id = useSelector(getUserInfo).detail.school_id
    const {data, isLoading} = useGetPartialSchoolQuery({
        school_id: school_id,
        section: section_name,
    });

    const section_data = data?.detail.data as T;

    return {section_data, isLoading};
};


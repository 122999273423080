import {Table, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import SizeBySex from "../../../components/SizeBySex/SizeBySex";
import React from "react";
import useSyncReduxCache from "../../../hooks/useSyncReduxCache";

export function TableExam({section, data}: {
    section: string,
    data: ExamResults,

}) {
    const [value,
        setValue] = useSyncReduxCache(data,
        section,
        `detail.data`
    );
    return <Table variant='striped' mb='24px' mt="12px">
        <Thead>
            <Tr>
                <Th></Th>
                <Th>CEP</Th>
                <Th>CEP Vie Active</Th>
                <Th>Total</Th>
            </Tr>
        </Thead>
        <Tbody>
            <Tr>
                <Td>Inscrit</Td>
                <Td><SizeBySex
                    value={value.cep.inscrits}
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                inscrits: {
                                    ...value.cep.inscrits,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                inscrits: {
                                    ...value.cep.inscrits,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                /></Td>
                <Td><SizeBySex
                    value={value.cep_vie_active.inscrits}
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                inscrits: {
                                    ...value.cep_vie_active.inscrits,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })
                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                inscrits: {
                                    ...value.cep_vie_active.inscrits,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })
                    }}
                /></Td>
                <Td><SizeBySex
                    value={{
                        M: value.cep.inscrits.M + value.cep_vie_active.inscrits.M,
                        F: value.cep.inscrits.F + value.cep_vie_active.inscrits.F
                    }}
                /></Td>

            </Tr>
            <Tr>
                <Td>Ont Composé</Td>
                <Td><SizeBySex
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                ont_compose: {
                                    ...value.cep.ont_compose,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                ont_compose: {
                                    ...value.cep.ont_compose,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    value={value.cep.ont_compose}
                /></Td>
                <Td><SizeBySex
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                ont_compose: {
                                    ...value.cep.ont_compose,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                ont_compose: {
                                    ...value.cep.ont_compose,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    value={value.cep_vie_active.ont_compose}
                /></Td>
                <Td><SizeBySex
                    value={{
                        M: value.cep.ont_compose.M + value.cep_vie_active.ont_compose.M,
                        F: value.cep.ont_compose.F + value.cep_vie_active.ont_compose.F
                    }}
                /></Td>

            </Tr>
            <Tr>
                <Td>Admis</Td>
                <Td><SizeBySex
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                admis: {
                                    ...value.cep.admis,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep: {
                                ...value.cep,
                                admis: {
                                    ...value.cep.admis,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    value={value.cep.admis}
                /></Td>
                <Td><SizeBySex
                    onChangeMale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                admis: {
                                    ...value.cep_vie_active.admis,
                                    M: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    onChangeFemale={(e) => {
                        setValue({
                            ...value,
                            cep_vie_active: {
                                ...value.cep_vie_active,
                                admis: {
                                    ...value.cep_vie_active.admis,
                                    F: parseInt(e.target.value)
                                }
                            }
                        })

                    }}
                    value={value.cep_vie_active.admis}
                /></Td>
                <Td><SizeBySex
                    value={{
                        M: value.cep.admis.M + value.cep_vie_active.admis.M,
                        F: value.cep.admis.F + value.cep_vie_active.admis.F
                    }}
                /></Td>

            </Tr>
        </Tbody>

    </Table>
}
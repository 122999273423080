import { Configure} from "react-instantsearch";


import {ChakraSearchBox} from "./ChakraSearchBox";
import {Flex} from "@chakra-ui/react";
import ChakraHits from "./ChakraHits";


export const Search = ({onClose}: {
    onClose: () => void

}) => {





    return (
        <>


            <Configure hitsPerPage={5}/>
            <Flex
                  w={"100%"} flexDirection={"column"}>
                <ChakraSearchBox/>
                 <ChakraHits
                     onClose={onClose}
                 />
            </Flex>

        </>
    );
};
import {Box, Flex, Grid, Input, Select, SimpleGrid, Text} from "@chakra-ui/react";
import React from "react";

import useSyncReduxCache from "../../../hooks/useSyncReduxCache";
import FormComponent from "../../../components/form/FormComponent";


export function FinancialTable({section, data}: {
    section: string,
    data: FinancialData
}) {
    const [value,
        setValue] = useSyncReduxCache(data,
        section,
        "detail.data");


    const formElements: FormElement[] = [
        {
            title: 'Solde de l\'exercice antérieur',
            component: <Input type={"number"}
                              value={value.previous_year_balance}
                              onChange={(e) => {
                                  setValue({
                                      ...value,
                                      previous_year_balance: parseInt(e.target.value)
                                  })
                              }}
                              width={"300px"}
            />
        },
        {
            title: 'Montant de la subvention reçue de l\'Etat',
            component: <Input type={"number"}
                              value={value.state_subsidy_amount}
                              onChange={(e) => {
                                  setValue({
                                      ...value,
                                      state_subsidy_amount: parseInt(e.target.value)
                                  })
                              }}
                              width={"300px"}
            />
        },
        {
            title: 'Autres cotisations des parents d\'élèves',
            component: <Input type={"number"}
                              value={value.other_parent_contributions}
                              onChange={(e) => {
                                  setValue({
                                      ...value,
                                      other_parent_contributions: parseInt(e.target.value)
                                  })
                              }}
                              width={"300px"}
            />
        },
        {
            title: 'Autres ressources financières',
            component: <Input type={"number"}

                              value={value.other_financial_resources}
                              onChange={(e) => {
                                  setValue({
                                      ...value,
                                      other_financial_resources: parseInt(e.target.value)
                                  })
                              }}
                              width={"300px"}
            />
        }, {
            title: 'Total',
            component: <Input type={"number"}
                              disabled={true}
                              value={value.state_subsidy_amount +
                                  value.other_parent_contributions +
                                  value.other_financial_resources +
                                  value.previous_year_balance}

                              width={"300px"}
            />
        }


    ]

    return <SimpleGrid columns={1} gap='20px' mb='20px'>
        <FormComponent
            id={"financial_data_table"}
            isCollapsable={false} title={"Donnée financière"}>
            {
                formElements.map((item, index) => (
                    <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            {item.title}
                        </Text>
                        {item.component}
                    </Flex>
                ))
            }
        </FormComponent>

    </SimpleGrid>

}
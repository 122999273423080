import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";

export function ManagementEntities({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const [value,
        setValue] = useSyncReduxCache(data.management_bodies,
        section,
        "detail.data.management_bodies");
    const managementEntities: FormElement[] = [
        {
            title: "Existe-t-il une Association des Parents d'Elèves ?",
            component: <Switch
                isChecked={value.has_parents_association}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_parents_association: !prevState.has_parents_association
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },
        {
            title: "Existe-t-il une Association des mères d'Elèves ?",
            component: <Switch
                isChecked={value.has_mothers_association}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_mothers_association: !prevState.has_mothers_association
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "Existe-t-il un comité de direction ? ",
            component: <Switch
                isChecked={value.has_management_committee}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_management_committee: !prevState.has_management_committee
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "Existe-t-il un conseil des maîtres ? ",
            component: <Switch
                isChecked={value.has_teachers_council}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_teachers_council: !prevState.has_teachers_council
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "Existe-t-il d'autres comités de gestion ? ",
            component: <Switch
                isChecked={value.has_other_management_committees}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_other_management_committees: !prevState.has_other_management_committees
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }
    ]
    return <>
        {
            managementEntities.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </>

}
import {Flex, Input, Select, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import {DistanceSelect} from "./DistanceSelect";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";

interface NearbyEstablishmentsInterface {
    distance_to_preschool: string
    distance_to_first_primary_school: string
    distance_to_second_primary_school: string
    distance_to_secondary_school: string

}

export function NearbyEstablishments({section, data}: {
    section: string,
    data: SocioEnvironnement
}) {
    const [value,
        setValue] = useSyncReduxCache(data.nearby_establishments,
        section,
        "detail.data.nearby_establishments");
    const {validationStatus} = useFormValidator<NearbyEstablishmentsInterface>(value, {});

    const nearbyEstablishments: FormElement[] = [
        {
            id: "distance_to_preschool",
            description: "Distance vers l'école maternelle la plus proche",
            title: "Ecole maternelle",
            component: <DistanceSelect
                value={value.distance_to_preschool}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_preschool: e.target.value}
                    })
                }}
            />
        },
        {
            id: "distance_to_first_primary_school",
            description: "Distance vers la première école primaire la plus proche",
            title: "1ère EP",
            component: <DistanceSelect
                value={value.distance_to_first_primary_school}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_first_primary_school: e.target.value}
                    })
                }}
            />
        }, {
            id: "distance_to_second_primary_school",
            description: "Distance vers la deuxième école primaire la plus proche",
            title: "2e EP",
            component: <DistanceSelect
                value={value.distance_to_second_primary_school}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_second_primary_school: e.target.value}
                    })
                }}
            />
        }, {
            title: "CEG ou Lycée",
            id: "distance_to_secondary_school",
            description: "Distance vers le collège ou le lycée le plus proche",
            component: <DistanceSelect
                value={value.distance_to_secondary_school}
                setValue={(e) => {
                    setValue((prev) => {
                        return {...prev, distance_to_secondary_school: e.target.value}
                    })
                }}
            />
        },
    ]
    return <FormComponent
        id={"nearby_establishments"}
        status={validationStatus.status}
        isCollapsable={true} title={"Etablissements environnants"}>
        {
            nearbyEstablishments.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text
                        id={item.id}
                        data-description={item.description}
                        w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </FormComponent>

}
import { mode } from '@chakra-ui/theme-tools';
const Card = {
	baseStyle: (props: any) => ({
		p: '20px',

		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		position: 'relative',

		borderRadius: '20px',
		minWidth: '0px',
		wordWrap: 'break-word',
		bg: mode('#ffffff', 'navy.800')(props),
		color: 'brand.900',
		backgroundClip: 'border-box',
		fontWeight: 'light'
	})
};

export const CardComponent = {
	components: {
		Card
	}
};

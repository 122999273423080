import {Flex, Input, Select, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";

interface ServiceAndEnvironnementInterface {
    is_school_flood_prone: boolean;
    is_locality_electrified: string[];
    water_supply: string[];
    is_school_environment_polluted: string[];

}

export function ServiceAndEnvironnement({section, data}: {
    section: string,
    data: SocioEnvironnement
}) {
    const [value,
        setValue] = useSyncReduxCache(data.services_and_environment,
        section,
        "detail.data.services_and_environment");
    const {validationStatus} = useFormValidator<ServiceAndEnvironnementInterface>(value, {});

    const waterSupply = [
        "Eau courante",
        "Puits",
        "Forage",
        "Citerne"]
    const electricitySupply = [
        "SBEE",
        "Solaire",
    ]
    const sonorPollution = [
        "Sonore",
        "Atmosphérique",
    ]
    const serviceAndEnvironnement: FormElement[] = [
        {
            title: "L'école est-elle inondable ?",
            id: "is_school_flood_prone",
            description: "Spécifie si l'école est inondable ou non.",
            component: <Switch
                isChecked={value.is_school_flood_prone}
                onChange={() => {
                    setValue(prevState => ({...prevState, is_school_flood_prone: !prevState.is_school_flood_prone}))
                }}
                variant='main' size='md'/>
        },
        {
            title: "La localité est-elle électrifiée ?",
            id: "is_locality_electrified",
            description: "Précise si la localité est électrifiée ou non.",
            component: <SelectCheckbox
                options={electricitySupply}
                checkedItems={value.is_locality_electrified}
                onChange={(checkedItems) => {
                    setValue(prevState => ({
                        ...prevState, is_locality_electrified: checkedItems
                    }))
                }}
            />
        }, {
            title: "Approvisionnement de la localité en eau potable ",
            id: "water_supply",
            description: "Précise les moyens d'approvisionnement en eau potable de la localité.",
            component: <SelectCheckbox
                options={waterSupply}
                checkedItems={value.water_supply}
                onChange={(checkedItems) => {
                    setValue(prevState => ({
                        ...prevState, water_supply: checkedItems
                    }))
                }}
            />
        }, {
            title: "L'environnement scolaire est-il pollué ?",
            id: "is_school_environment_polluted",
            description: "Précise les types de pollution de l'environnement scolaire.",

            component: <SelectCheckbox
                options={sonorPollution}
                checkedItems={value.is_school_environment_polluted}
                onChange={(checkedItems) => {
                    setValue(prevState => ({
                        ...prevState, is_school_environment_polluted: checkedItems
                    }))
                }}
            />
        },
    ]
    return <FormComponent
        id={"services_and_environment"}
        status={validationStatus.status}
        isCollapsable={true} title={"Service et Environnement"}>
        {
            serviceAndEnvironnement.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text
                          id={item.id}
                                data-description={item.description}
                        w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </FormComponent>

}
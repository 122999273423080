// Chakra imports
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, Button,
    Flex,
    Input,
    Select,
    Switch,
    Tab,
    TabList, TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
// Custom components


// Assets
import React from "react";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import {useForm} from "react-hook-form";

function replaceEmptyStringsWithNull(obj: object): any {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
            if (value === "") {
                return [key, null];  // Remplace les chaînes vides par null
            } else if (typeof value === "object" && value !== null) {
                // Si la valeur est un objet, on applique récursivement la fonction
                return [key, replaceEmptyStringsWithNull(value)];
            } else {
                return [key, value];  // Sinon, on garde la valeur inchangée
            }
        })
    );
}

export default function Personnel(props: {
    selectedPersonnel: Staff | null,
    setPersonnelList: (prev: Staff[]) => void,
    title: string,
    onClose: () => void,
    section: string,
    helpMessage?: string,
    [x: string]: any
}) {
    const {title, formItems, onClose, helpMessage, selectedPersonnel, setPersonnelList,
        section, ...rest} = props;
    const {
        register,
        handleSubmit
    } = useForm()

    const submitForm = (data: Staff) => {
        console.log("data", data)
        const improvedData = replaceEmptyStringsWithNull(data)
        // @ts-ignore
        setPersonnelList((prev) => {
                return prev.map((person: Staff) => {
                        if (person.matricule === selectedPersonnel.matricule) {
                            return {
                                ...person,
                                ...improvedData,
                                real_indice: improvedData.real_indice === null ? improvedData.real_indice : parseInt(improvedData.real_indice),
                                seniority: {
                                    public: improvedData.seniority.public === null ? improvedData.seniority.public : parseInt(improvedData.seniority.public),
                                    department: improvedData.seniority.department === null ? improvedData.seniority.department : parseInt(improvedData.seniority.department),
                                    school: improvedData.seniority.school === null ? improvedData.seniority.school : parseInt(improvedData.seniority.school)
                                },
                                number_of_visit: {
                                    inspector: improvedData.number_of_visit.inspector === null ? improvedData.number_of_visit.inspector : parseInt(improvedData.number_of_visit.inspector),
                                    director: improvedData.number_of_visit.director === null ? improvedData.number_of_visit.director : parseInt(improvedData.number_of_visit.director),
                                    cp: improvedData.number_of_visit.cp === null ? improvedData.number_of_visit.cp : parseInt(improvedData.number_of_visit.cp)
                                },
                                up_participation: improvedData.up_participation === null ? improvedData.up_participation : parseInt(improvedData.up_participation)

                            }
                        } else {
                            return person
                        }
                    }
                )
            }
        )
        onClose()
    }
    // Chakra Color Mode

    return (
        // @ts-ignore

        <form onSubmit={handleSubmit(submitForm)}>
            <Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
                <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                    <Text

                        fontSize='lg'
                        fontWeight='700'

                    >
                        {title}
                    </Text>
                    <Help helpMessage={helpMessage}/>
                </Flex>
                <Box px='11px' w='100%'>
                    <Tabs variant='soft-rounded' colorScheme={"brand"}>
                        <TabList>
                            <Tab>1</Tab>
                            <Tab>2</Tab>
                            <Tab>3</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Matricule
                                    </Text>
                                    <Input
                                        disabled={true}
                                        defaultValue={selectedPersonnel.matricule}
                                        required
                                        width={"300px"}
                                        type={"text"}/>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nom
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.name}
                                        {...register('name')}
                                        required

                                        width={"300px"}
                                        type={"text"}/>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Prénom
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.surname}
                                        required
                                        {...register('surname')}
                                        width={"300px"}
                                        type={"text"}/>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Sexe
                                    </Text>
                                    <Select
                                        width={"300px"}
                                        {...register('sexe')}
                                        defaultValue={selectedPersonnel.sexe}
                                    >
                                        <option value="M">M</option>
                                        <option value="F">F</option>

                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Année de naissance
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.birth_year}
                                        {...register('birth_year')}
                                        type="number" min="1900" max="2099" step="1"
                                        width={"300px"}
                                    />
                                </Flex>
                                <Accordion w='100%' mb='20px' allowToggle>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Text w={"200px"} fontSize='md'
                                                      textAlign='start'>
                                                    Diplôme le plus elevé
                                                </Text>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Flex flexDirection='column'>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Académique
                                                    </Text>
                                                    <Select
                                                        width={"300px"}
                                                        defaultValue={selectedPersonnel.highest_degree.academic}
                                                        {...register('highest_degree.academic')}
                                                    >
                                                        <option value='CEP'>CEP</option>
                                                        <option value='BEPC'>BEPC</option>
                                                        <option value="BAC">BAC</option>
                                                        <option value="LICENSE">LICENSE</option>
                                                        <option value="MASTER">MASTER</option>
                                                    </Select>
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Professionnel
                                                    </Text>
                                                    <Select
                                                        defaultValue={selectedPersonnel.highest_degree.professional}
                                                        {...register('highest_degree.professional')}
                                                        width={"300px"}
                                                    >
                                                        <option value='CEAP'>CEAP</option>
                                                        <option value='CAP'>CAP</option>
                                                    </Select>
                                                </Flex>

                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>


                            </TabPanel>
                            <TabPanel>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Grade
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.grade}
                                        {...register('grade')}
                                        width={"300px"}
                                    />
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Statut
                                    </Text>
                                    <Select
                                        width={"300px"}
                                        defaultValue={selectedPersonnel.statut}
                                        {...register('statut')}
                                    >
                                        <option value="ACPDE">ACPDE</option>
                                        <option value="ACE">ACE</option>
                                        <option value="AME">AME</option>
                                        <option value="Communautaire">Communautaire</option>
                                        <option value="Fonctionnaire de l'état">Fonctionnaire de l'état</option>
                                        <option value="Privé">Privé</option>

                                        <option value="Autre">Autre</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Fonction
                                    </Text>
                                    <Select
                                        width={"300px"}
                                        defaultValue={selectedPersonnel.fonction}
                                        {...register('fonction')}
                                    >
                                        <option value="Directeur">Directeur</option>
                                        <option value="Adjoint">Adjoint</option>
                                        <option value="Enseignant">Enseignant</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Indice réel
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.real_indice}
                                        {...register('real_indice')}
                                        type="number" step="1"
                                        width={"300px"}/>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Cours tenu
                                    </Text>
                                    <SelectCheckbox
                                        options={[
                                            "CI",
                                            "CP",
                                            "CE1",
                                            "CE2",
                                            "CM1",
                                            "CM2",

                                        ]}
                                        onChange={() => {
                                            console.log("changed")
                                        }}
                                    />
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Accordion w='100%' mb='20px' allowToggle>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Text w={"200px"} fontSize='md'
                                                      textAlign='start'>
                                                    Ancienneté
                                                </Text>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Flex flexDirection='column'>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Fonction publique
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.seniority.public}
                                                        {...register('seniority.public')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Département
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.seniority.department}
                                                        {...register('seniority.department')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Ecole
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.seniority.school}
                                                        {...register('seniority.school')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>

                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Date de dernière formation
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.last_formation_date}
                                        {...register('last_formation_date')}
                                        type="date"
                                        width={"300px"}/>
                                </Flex>

                                <Accordion w='100%' mb='20px' allowToggle>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Text w={"200px"} fontSize='md'
                                                      textAlign='start'>
                                                    Nombre de visite
                                                </Text>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Flex flexDirection='column'>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Inspecteur
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.number_of_visit.inspector}
                                                        {...register('number_of_visit.inspector')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Directeur
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.number_of_visit.director}
                                                        {...register('number_of_visit.director')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        CP
                                                    </Text>
                                                    <Input
                                                        defaultValue={selectedPersonnel.number_of_visit.cp}
                                                        {...register('number_of_visit.cp')}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>

                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Participation aux UP (%)
                                    </Text>
                                    <Input
                                        defaultValue={selectedPersonnel.up_participation}
                                        {...register('up_participation')}
                                        type="number"
                                        width={"300px"}/>
                                </Flex>

                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
                <Button type={"submit"} my={"10px"} bgColor={"brand.900"} color={"white"}>Enregistrer</Button>

            </Card>
        </form>
    );
}

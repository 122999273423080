import {createSlice} from "@reduxjs/toolkit";


const initialState: EditionState = {
    editionInProgress: false,
    editedSection: '',

}
const edition = createSlice({
        name: 'edition',
        initialState: initialState,
        reducers: {
            startEdition: (state, action: {
                payload: {
                    section: string
                }
            }) => {
                state.editionInProgress = true;
                state.editedSection = action.payload.section;
            },
            endEdition: (state) => {
                state.editionInProgress = false;
                state.editedSection = '';
            },

        }
    }
)

export const {
    startEdition,
    endEdition,
} = edition.actions;
export default edition.reducer;
import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";

export function ServicesAndEquipments({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const [value,
        setValue] = useSyncReduxCache(data.school_services_and_equipment,
        section,
        "detail.data.school_services_and_equipment");
    const servicesAndEquipments: FormElement[] = [
        {
            title: 'L\'école est-elle alimentée en électricité ?',
            component: <Switch
                isChecked={value.is_electrified}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        is_electrified: !prevState.is_electrified
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },
        {
            title: "L'école dispose-t-elle d'une cantine fonctionnelle ?",
            component: <Switch
                isChecked={value.has_functional_canteen}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_functional_canteen: !prevState.has_functional_canteen
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école dispose-t-elle d'un équipement de cuisine ?",
            component: <Switch
                isChecked={value.has_kitchen_equipment}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_kitchen_equipment: !prevState.has_kitchen_equipment
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école dispose-t-elle d'un magasin de vivres en bon état ?",
            component: <Switch
                isChecked={value.has_food_store_in_good_condition}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_food_store_in_good_condition: !prevState.has_food_store_in_good_condition
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école a-t-elle une bibliothèque fonctionnelle ?",
            component: <Switch
                isChecked={value.has_functional_library}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_functional_library: !prevState.has_functional_library
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: "L'école a-t-elle une boîte à pharmacie ?",
            component: <Switch
                isChecked={value.has_first_aid_kit}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_first_aid_kit: !prevState.has_first_aid_kit
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },

    ]
    return <>
        {
            servicesAndEquipments.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </>

}
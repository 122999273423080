import {Icon} from '@chakra-ui/react';

// Admin Imports
import IdAndLocation from './views/admin/identificationAndLocation/identificationAndLocation';
import SocioEnvironnement from './views/admin/socioEnvironnement/socioEnvironnement';
import GeneralInfo from './views/admin/generalInfo/generalInfo';
import OfficeAndFurniture from "./views/admin/officeAndFurniture/officeAndFurniture";
import {TeachingAndLearningEquipment} from "./views/admin/teachingAndLearningEquipment/teachingAndLearningEquipment";
import {TeachingStaff} from "./views/admin/teachingStaff/teachingStaff";
import {
    TbNumber10Small,
    TbSquareRoundedNumber1Filled,
    TbSquareRoundedNumber2Filled,
    TbSquareRoundedNumber3Filled,
    TbSquareRoundedNumber4Filled,
    TbSquareRoundedNumber5Filled,
    TbSquareRoundedNumber6Filled,
    TbSquareRoundedNumber7Filled,
    TbSquareRoundedNumber8Filled, TbSquareRoundedNumber9Filled,
} from "react-icons/tb";
import {ParticipatorySchoolManagement} from "./views/admin/participatorySchoolManagement/participatorySchoolManagement";
import {FinancialData} from "./views/admin/financialData/financialData";
import {StudentsRecord} from "./views/admin/studentsRecord/studentsRecord";
import {ExamResult} from "./views/admin/ExamResult/examResult";
import SignIn from "./views/auth/signIn";
import {MdLock} from "react-icons/md";
import React from "react";


const iconSize = '25px';

const routes: RoutesType[] = [
    {
        name: 'Connexion',
        layout: '/auth',
        path: '',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: SignIn,
        protected: false
    },
    {
        name: "Identification et localisation de l'école",
        identifier: 'id_and_location',
        layout: '/formulaire',
        path: '/id_and_location',
        icon: <Icon as={TbSquareRoundedNumber1Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: IdAndLocation,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à l'identification et la localisation de l'école."

    },
    {
        name: 'Environnement socio économique',
        identifier: 'socio_environnement',
        layout: '/formulaire',
        path: '/socio_environnement',
        icon: <Icon as={TbSquareRoundedNumber2Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: SocioEnvironnement,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à l'environnement socio économique de l'école."
    },
    {
        name: "Information générale sur l'école",
        identifier: 'general_info',
        layout: '/formulaire',
        path: '/general_info',
        icon: <Icon as={TbSquareRoundedNumber3Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: GeneralInfo,
        protected: true,
        description: "Cette section permet de renseigner les informations générales sur l'école."

    },
    {
        name: "Caractéristiques des locaux et mobiliers",
        identifier: 'office_and_furniture',
        layout: '/formulaire',
        path: '/office_and_furniture',
        icon: <Icon as={TbSquareRoundedNumber4Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: OfficeAndFurniture,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives aux locaux et mobiliers de l'école."
    },

    {
        name: 'Equipements didactiques et pédagogiques',
        identifier: 'teaching_and_learning_equipment',
        layout: '/formulaire',
        path: '/teaching_and_learning_equipment',
        icon: <Icon as={TbSquareRoundedNumber5Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: TeachingAndLearningEquipment,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à l'équipement didactique et pédagogique de l'école."
    },
    {
        name: 'Etat nominatif du personnel enseignant',
        identifier: 'teaching_staff',
        layout: '/formulaire',
        path: '/teaching_staff',
        icon: <Icon as={TbSquareRoundedNumber6Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: TeachingStaff,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à l'état nominatif du personnel enseignant de l'école."

    },
    {
        name: 'Résultats aux examens de la dernière session',
        identifier: 'exam_results',
        layout: '/formulaire',
        path: '/exam_results',
        icon: <Icon as={TbSquareRoundedNumber7Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: ExamResult,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives aux résultats aux examens de la dernière session."

    },
    {
        name: 'Gestion participative de l\'école',
        identifier: 'participatory_school_management',
        layout: '/formulaire',
        path: '/participatory_school_management',
        icon: <Icon as={TbSquareRoundedNumber8Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: ParticipatorySchoolManagement,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à la gestion participative de l'école."

    },

    {
        name: 'Données financières',
        identifier: 'financial_data',
        layout: '/formulaire',
        path: '/financial_data',
        icon: <Icon as={TbSquareRoundedNumber9Filled} width={iconSize} height={iconSize} color='inherit'/>,
        component: FinancialData,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives aux données financières de l'école"

    },
    {
        name: 'Effectif des élèves',
        identifier: 'students_record',
        layout: '/formulaire',
        path: '/students_record',
        icon: <Icon as={TbNumber10Small} width={iconSize} height={iconSize} color='inherit'/>,
        component: StudentsRecord,
        protected: true,
        description: "Cette section permet de renseigner les informations relatives à l'effectif des élèves de l'école."

    }
];

export default routes;

import React from 'react';
import ContextButton from "../icons/ContextButton";
import {IoIosHelpCircleOutline} from "react-icons/io";
import {useColorModeValue} from "@chakra-ui/react";



export default function Help(props: { helpMessage: string, [x: string]: any }) {
    const {helpMessage, ...rest} = props;
    const iconColor = useColorModeValue('brand.500', 'white');




    return (
        <ContextButton iconColor={iconColor} icon={IoIosHelpCircleOutline} contextComponent={<>
            {helpMessage}
        </>} {...rest}/>);

}

// Chakra imports
import {
    Box, Button,
    Flex,
    Input,
    Select,

    Text,
} from '@chakra-ui/react';
// Custom components


// Assets
import React from "react";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import {useForm} from "react-hook-form";
import {userLogin} from "../../../../thunks/login";

export default function PersonnelCreation(props: {
    onClose: () => void,
    title: string,
    setPersonnelList: (prev: Staff[]) => void,
    section: string,
    helpMessage?: string,
    [x: string]: any
}) {
    const {title, onClose, setPersonnelList, helpMessage} = props;

    const {
        register,
        handleSubmit
    } = useForm()
    const submitForm = (data: any) => {
        console.log("data", data)
        // @ts-ignore

        setPersonnelList((prev) => {
            return [
                ...prev,
                {
                    "matricule": data.matricule,
                    "name": data.name,
                    "surname": data.surname,
                    "sexe": data.sexe,
                    "birth_year": null,
                    "highest_degree": {
                        "academic": null,
                        "professional": null
                    },
                    "grade": null,
                    "statut": null,
                    "fonction": null,
                    "real_indice": null,
                    "teached_subjects": null,
                    "seniority": {
                        "public": null,
                        "department": null,
                        "school": null
                    },
                    "last_formation_date": null,
                    "number_of_visit": {
                        "inspector": null,
                        "director": null,
                        "cp": null
                    },
                    "up_participation": null,
                }
            ]
        })
        onClose()
    }
    // @ts-ignore
    return (<form onSubmit={handleSubmit(submitForm)}>

            <Card>

                <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                    <Text

                        fontSize='lg'
                        fontWeight='700'

                    >
                        {title}
                    </Text>
                    <Help helpMessage={helpMessage}/>
                </Flex>
                <Box px='11px' w='100%'>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Matricule
                        </Text>
                        <Input
                            required={true}
                            {...register('matricule')}
                            width={"300px"}
                            type={"text"}/>
                    </Flex>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Nom
                        </Text>
                        <Input
                            required={true}

                            {...register('name')}
                            width={"300px"}
                            type={"text"}/>
                    </Flex>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Prénom
                        </Text>
                        <Input
                            required={true}

                            {...register('surname')}
                            width={"300px"}
                            type={"text"}/>

                    </Flex>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Sexe
                        </Text>
                        <Select
                            {...register('sexe')}
                            width={"300px"}
                            defaultValue={"M"}
                        >
                            <option value="M">M</option>
                            <option value="F">F</option>

                        </Select>
                    </Flex>


                </Box>

                <Button type={"submit"} my={"10px"} bgColor={"brand.900"} color={"white"}>Enregistrer</Button>
            </Card>
        </form>

    )
        ;
}

import {IoIosCheckmarkCircle, IoIosCloseCircleOutline, IoIosWarning} from "react-icons/io";
import {ValidationStatus} from "./enum";
export const alertIcon = {
    [ValidationStatus.Valid]: {
        icon: IoIosCheckmarkCircle,
        color: "green.500",
        message: "Rien à signaler, cette étape est complétée."
    },
    [ValidationStatus.Error]: {
        icon: IoIosCloseCircleOutline,
        color: "red.500",
        message: "Il y a des erreurs dans cette étape, veuillez vérifier les informations saisies."
    },
    [ValidationStatus.Incomplete]: {
        icon: IoIosWarning,
        color: "yellow.500",
        message: "Attention certain champs n'ont pas été remplis "
    },
}


// Chakra Imports
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode, Box
} from '@chakra-ui/react';
// Custom Components
import {ItemContent} from 'components/menu/ItemContent';
import {SearchBar} from 'components/navbar/searchBar/SearchBar';
import {SidebarResponsive} from 'components/sidebar/Sidebar';
import React from 'react';
// Assets
import {MdNotificationsNone, MdInfoOutline} from 'react-icons/md';
import {FaEthereum} from 'react-icons/fa';
import routes from 'routes';
import {AiOutlinePercentage} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {userLogout} from "../../thunks/logout";
import {Loader} from "../Loader/Loader";
import {schoolsApi} from "../../services/Schools";
import {getUserInfo} from "../../slices/selector";

export default function HeaderLinks(props: {
    secondary: boolean

}) {
    const {
        secondary,

    } = props;
    const school_id = useSelector(getUserInfo).detail.school_id

    const {data} = useSelector((state) =>
        schoolsApi.endpoints.getCompletionScore.select({
            school_id: school_id,
            // @ts-ignore
        })(state)
    );
    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const dispatch = useDispatch()
    const token = useSelector((state: { authentication: IAuthState }) => state.authentication.userToken)
    const isLoading = useSelector((state: { authentication: IAuthState }) => state.authentication.loading)
    const getColorForPercentage = (percentage: number) => {
        if (percentage < 20) return 'rgba(255, 0, 0, 0.5)'; // Rouge transparent
        if (percentage >= 20 && percentage < 60) return 'rgba(255, 165, 0, 0.5)'; // Orange transparent
        if (percentage >= 60) return 'rgba(0, 128, 0, 0.5)'; // Vert transparent
    };
    const percentage = Math.round(data?.detail.completion_score.total)

    return (
        <Flex
            w={{sm: '100%', md: 'auto'}}
            alignItems='center'
            flexDirection='row'
            bg={menuBg}
            flexWrap={secondary ? {base: 'wrap', md: 'nowrap'} : 'unset'}
            p='10px'
            borderRadius='30px'
            boxShadow={shadow}>
            <SearchBar
                mb={() => {
                    if (secondary) {
                        return {base: '10px', md: 'unset'};
                    }
                    return 'unset';
                }}
                me='10px'
                borderRadius='30px'
            />
            <Flex
                bg={ethBg}
                display={'flex'}
                borderRadius='30px'
                ms='auto'
                p='6px'
                align='center'
                me='6px'>


                <Text w='max-content' color={ethColor} fontSize='sm' fontWeight='700' me='6px'>
                    {percentage}%
                </Text>
                <Flex align='center' justify='center' bg={ethBox} h='29px' w='29px' borderRadius='30px'>

                    <Icon color={ethColor} w='14px' h='14px' as={AiOutlinePercentage}/>
                </Flex>
            </Flex>
            <SidebarResponsive routes={routes}/>
            <Menu>
                <MenuButton p='0px'>
                    <Icon mt='6px' as={MdNotificationsNone} color={navbarIcon} w='18px' h='18px' me='10px'/>
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p='20px'
                    borderRadius='20px'
                    bg={menuBg}
                    border='none'
                    mt='22px'
                    me={{base: '30px', md: 'unset'}}
                    minW={{base: 'unset', md: '400px', xl: '450px'}}
                    maxW={{base: '360px', md: 'unset'}}>
                    <Flex w='100%' mb='20px'>
                        <Text fontSize='md' fontWeight='600' color={textColor}>
                            Notifications
                        </Text>
                        <Text fontSize='sm' fontWeight='500' color={textColorBrand} ms='auto' cursor='pointer'>
                            Marquer tout comme lu
                        </Text>
                    </Flex>
                    <Flex flexDirection='column'>
                        <MenuItem _hover={{bg: 'none'}} _focus={{bg: 'none'}} px='0' borderRadius='8px' mb='10px'>
                            <ItemContent title='Nouvelle campagne de remplissage'
                                         description={"Veuillez remplir le formulaire avant sa clôture"}/>
                        </MenuItem>

                    </Flex>
                </MenuList>
            </Menu>


            <Menu>
                <MenuButton p='0px'>
                    <Avatar
                        _hover={{cursor: 'pointer'}}
                        color='white'
                        name='Carlos Oke'
                        bg='brand.900'
                        size='sm'
                        w='40px'
                        h='40px'
                    />
                </MenuButton>
                <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
                    <Flex w='100%' mb='0px'>
                        <Text
                            ps='20px'
                            pt='16px'
                            pb='10px'
                            w='100%'
                            borderBottom='1px solid'
                            borderColor={borderColor}
                            fontSize='sm'
                            fontWeight='700'
                            color={textColor}>
                            Carlos Oke
                        </Text>
                    </Flex>
                    <Flex flexDirection='column' p='10px'>
                        <MenuItem _hover={{bg: 'none'}} _focus={{bg: 'none'}} borderRadius='8px' px='14px'>
                            <Text fontSize='sm'>Paramètre compte</Text>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                // @ts-ignore
                                dispatch(userLogout(token))
                            }}
                            _hover={{bg: 'none'}}
                            _focus={{bg: 'none'}}
                            borderRadius='8px'
                            px='14px'>
                            {isLoading ? <Loader/> : <Text fontSize='sm'>Déconnexion</Text>}
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}


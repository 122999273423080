import {
    Flex, useDisclosure,

} from '@chakra-ui/react';
import ChakraModal from "../../Modal/ChakraModal";
import React from "react";
import Card from "../../card/Card";
import {Search} from "../../SearchBox/Search";
import {SearchInput} from "./SearchInput";

export function SearchBar(props: {
    [x: string]: any;
}) {
    // Pass the computed styles into the `__css` prop
    const {...rest} = props;

    const {isOpen, onOpen, onClose} = useDisclosure()
    // Chakra Color Mode

    return (
        <>
            <ChakraModal isOpen={isOpen}
                         size={"xl"}
                         onClose={onClose}>

                <Card>

                    <Flex justifyContent={"space-between"} alignItems='center'  w='100%' mb='30px'>
                        <Search
                            onClose={onClose}
                        />


                    </Flex>
                </Card>
            </ChakraModal>
            <SearchInput

                onClick={onOpen}
                         readOnly={true}
                         {...rest}/>
        </>
    );
}

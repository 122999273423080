import {
    Checkbox,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button, IconButton,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {ChevronDownIcon} from "@chakra-ui/icons";

interface MultiSelectCheckboxProps {
    options: string[];
    checkedItems?: string[];
    onChange: (selectedValues: string[]) => void;
}


function SelectCheckbox({options, checkedItems, onChange}: MultiSelectCheckboxProps) {
    // @ts-ignore
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (value === undefined ||
            value === "") {
            return;
        }
        if (!checkedItems) {
            onChange([value]);
            return;
        }
        const newCheckedItems = checkedItems.includes(value) ? checkedItems.filter(item => item !== value) : [...checkedItems, value];
        onChange(newCheckedItems);

    };

    return (<>
            <Menu closeOnSelect={false}>
                <MenuButton as={IconButton} icon={<ChevronDownIcon/>}/>

                <MenuList>
                    {
                        options.map((option, index) => (
                            <MenuItem key={index} onClick={handleCheckboxChange}>
                                <Checkbox w={'100%'} value={option} isChecked={
                                    checkedItems && checkedItems.includes(option)
                                }
                                >
                                    {option}
                                </Checkbox>
                            </MenuItem>
                        ))
                    }


                </MenuList>
            </Menu>
        </>
    );
}

export default SelectCheckbox;

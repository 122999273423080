import {IconButton, Input, InputGroup, InputLeftElement, useColorModeValue} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import React from "react";

export function SearchInput(props: {
    onBlur?: () => void;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    onFocus?: () => void;
    autoFocus?: boolean;
    value?: string;
    [x: string]: any;
}) {
    const {onBlur, autoFocus, onChange, value, onFocus, onClick,readOnly, ...rest} = props;

    const searchIconColor = useColorModeValue('gray.700', 'white');
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const inputText = useColorModeValue('gray.700', 'gray.100');
    return <InputGroup w={{base: '100%', md: '200px'}} {...rest}>
        <InputLeftElement
            children={
                <IconButton
                    aria-label='search'
                    bg='inherit'
                    borderRadius='inherit'
                    _active={{
                        bg: 'inherit',
                        transform: 'none',
                        borderColor: 'transparent'
                    }}
                    _focus={{
                        boxShadow: 'none'
                    }}
                    icon={<SearchIcon color={searchIconColor} w='15px' h='15px'/>}
                />
            }
        />
        <Input
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onClick}
            onChange={onChange}
            autoFocus={autoFocus}
            readOnly={readOnly}
            type={"search"}
            value={value}
            variant='search'
            fontSize='sm'
            bg={inputBg}
            color={inputText}
            fontWeight='500'
            _placeholder={{color: 'gray.400', fontSize: '14px'}}
            borderRadius={'30px'}
            placeholder={ 'Rechercher'}
        />
    </InputGroup>
}
import {Box, Flex, SimpleGrid, useDisclosure} from "@chakra-ui/react";

import ChakraModal from "../../../../components/Modal/ChakraModal";

import {ChakraAlerteDialog} from "../../../../components/Modal/ChakraAlerteDialog";
import AddButton from "../../../../components/AddButton/AddButton";
import React from "react";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import ActionButton from "../../../../components/icons/ActionButton";
import {CiEdit, CiTrash} from "react-icons/ci";
import {TableData} from "../../../../components/dataTable/TableData";
import LocalCreation from "./LocalCreation";
import Local from "./Local";


export function Main({section, data}: {
    section: string,
    data: OfficeAndFurniture
}) {


    const {isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd} = useDisclosure()
    const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure()
    const {isOpen: isOpenEdition, onOpen: onOpenEdition, onClose: onCloseEdition} = useDisclosure()
    const [selectedRow, setSelectedRow] = React.useState<Office | null>(null)
    const [value,
        setValue
    ] = useSyncReduxCache(
        data.offices,
        section,
        "detail.data.offices");


    const columns: DataColumn[] = [
        {
            header: "Nom",
            accessor: "name",
            kind: "text",
            isEditable: false,
            width: '40%'
        },

        {
            header: "Type de local",
            accessor: "local_type",
            kind: "select",
            isEditable: true,
            width: '30%',
            options: [
                {value: "Salle", label: "Salle"},
                {value: "Bureau", label: "Bureau"},
                {value: "Magasin", label: "Magasin"},
                {value: "Latrine", label: "Latrine"},
                {value: "Cuisine", label: "Cuisine"},
                {value: "Bibliothèque", label: "Bibliothèque"},
                {value: "Logement", label: "Logement"},
                {value: "Salle informatique", label: "Salle informatique"}
            ]

        },
        {
            header: "Année de mise en service",
            accessor: "year_of_commissioning",
            kind: "number",
            isEditable: true,
            width: '23%',

        },


        {
            header: "Actions",
            kind: 'action',
            isEditable: true,
            component: ({row}) => <Flex align={"center"}>
                <ActionButton icon={CiEdit} onClick={
                    () => {
                        setSelectedRow(row)
                        onOpenEdition()
                    }
                } mr={2}/>
                <ActionButton bg={"red.500"} onClick={() => {
                    setSelectedRow(row)
                    onOpenDelete()
                    /* show the clicked row */

                }} color={"white"} icon={CiTrash}/>
            </Flex>

        }
    ]
    return (
        <>

            <ChakraModal isOpen={isOpenAdd} onClose={onCloseAdd}>

                <LocalCreation
                    section={section}
                    onClose={onCloseAdd}
                    setLocalList={setValue}
                    helpMessage={"Ajouter un local"}
                    title={"Ajouter un local"}
                />
            </ChakraModal>
            <ChakraModal isOpen={isOpenEdition} onClose={onCloseEdition}>

                <Local
                    onClose={onCloseEdition}
                    selectedLocal={selectedRow}
                    setLocalList={setValue}
                    section={section}
                    helpMessage={"Modifier un local"}
                    title={"Caractéristiques"}
                />
            </ChakraModal>
            <ChakraAlerteDialog isOpen={isOpenDelete} onClose={onCloseDelete}
                                title={"Supprimer le local"} message={"Voulez vous supprimer ce local"}/>

            <TableData<Office>
                id={"offices_table"}
                columns={columns}
                setValue={setValue}
                data={value}
                helperComponent={<AddButton onClick={onOpenAdd} message={"Ajouter un local"}/>}
                title={"Liste des locaux et mobiliers"} helpMessage={"Liste des locaux et mobiliers"}/>


        </>
    )
}
import {Select} from "@chakra-ui/react";
import * as React from "react";

export function DistanceSelect({value, setValue}: {
    value: string,
    setValue: (value: any) => void
}) {
    return <Select
        maxWidth={"300px"}
        placeholder={"Sélectionner une distance"}
        value={value}
        onChange={setValue}
    >
        <option value="<=0.5">&lsaquo;=0.5</option>
        <option value="Entre 0.5 et 1km">Entre 0.5 et 1km</option>
        <option value="Entre 1 et 3km">Entre 1 et 3km</option>
        <option value=">=3">&rsaquo;=3</option>
    </Select>
}
import {Button, Flex, Input, Select, Text} from "@chakra-ui/react";
import * as React from "react";
import arrondissement from "../../../../assets/json/arrondissements.json"
import {Loader} from "../../../../components/Loader/Loader";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";

import useFormValidator from "../../../../hooks/useFormValidator";
import PhoneInput from "../../../../components/PhonesInput/PhonesInput";


interface LocaTerriInterface {
    departement: string;
    commune: string;
    arrondissement: string;
    village: string;
    telephone: string[];
    email: string;
}


const validators: Validators<LocaTerriInterface> = {
    email: {
        validator: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        message: "L'email est invalide."
    },
    telephone: {
        validator: (value: string[]) => {
            return value.every((phone) => /^\d{8}$/.test(phone))
        },
        message: "Veuillez vérifier vos numéros de téléphone."
    }
};

export function LocaTerri({data, loading, section}: {
    data: IdAndLocation | null,
    section: string,
    loading: boolean
}) {

    const [localisation, setLocalisation] = useSyncReduxCache(data.loca_territoire,
        section,
        "detail.data.loca_territoire");
    const {validationStatus, getFieldProps} = useFormValidator<LocaTerriInterface>(localisation, validators);

    const render = () => {
        if (loading) {
            return <Loader/>
        } else if (data) {

            const formElements: FormElement[] = [
                {
                    id: "departement",
                    description: "Contiens le nom du département dans lequel se trouve l'établissement.",
                    title: 'Département',
                    component: <Select
                        width={"300px"}
                        value={localisation.departement}
                        onChange={(e) => {
                            setLocalisation({
                                ...localisation,
                                departement: e.target.value,
                                commune: '',
                                arrondissement: '',

                            })
                        }}
                        placeholder="Selectionner un département">
                        {
                            Object.keys(arrondissement).sort(
                                (a, b) => a.localeCompare(b)
                            ).map((item, index) => (
                                <option key={index}
                                        value={item}>{item}</option>
                            ))
                        }
                    </Select>
                },
                {
                    id: "commune",
                    description: "Contiens le nom de la commune dans laquelle se trouve l'établissement",
                    title: 'Commune',
                    component: <Select
                        width={"300px"}
                        value={localisation.commune}
                        onChange={(e) => {
                            setLocalisation({
                                ...localisation,
                                commune: e.target.value,
                                arrondissement: ''
                            })
                        }}
                        placeholder="Selectionner une commune">
                        {
                            // @ts-ignore
                            localisation.departement &&
                            // @ts-ignore
                            Object.keys(arrondissement[localisation.departement]).sort(
                                (a, b) => a.localeCompare(b)
                            ).map((item, index) => (
                                <option key={index}
                                        value={item}>{item}</option>
                            ))
                        }

                    </Select>
                },
                {
                    id: "arrondissement",
                    description: "Contiens le nom de l'arrondissement dans lequel se trouve l'établissement.",
                    title: 'Arrondissement',
                    component: <Select

                        value={localisation.arrondissement}
                        width={"300px"}
                        onChange={(e) => {
                            setLocalisation({
                                ...localisation,
                                arrondissement: e.target.value
                            })
                        }}
                        placeholder="Selectionner une commune">
                        {
                            // @ts-ignore
                            localisation.departement &&
                            // @ts-ignore
                            localisation.commune &&
                            // @ts-ignore
                            arrondissement[localisation.departement][localisation.commune].sort(
                                (a: string, b: string) => a.localeCompare(b)
                            ).map((item: string, index: number) => (
                                <option key={index}
                                        value={item}>{item}</option>
                            ))
                        }
                    </Select>
                },
                {
                    id: "village",
                    description: "Contiens le nom du village ou quartier de ville dans lequel se trouve l'établissement.",
                    title: 'Village ou Quartier de ville',
                    component: <Input type={"text"}
                                      value={data.loca_territoire.village}
                                      onChange={(e) => {
                                          setLocalisation({
                                              ...localisation,
                                              village: e.target.value
                                          })
                                      }}
                                      width={"300px"}
                    />
                },
                {
                    id: "adresse",
                    description: "Indique l'adresse de l'établissement.",
                    title: 'Adresse',
                    component: <Input type={"text"}
                                      value={data.loca_territoire.adresse}
                                      onChange={(e) => {
                                          setLocalisation({
                                              ...localisation,
                                              adresse: e.target.value
                                          })
                                      }}
                                      width={"300px"}
                    />
                },
                {
                    id: "postal_code",
                    description: "Indique la boîte postale de l'établissement.",
                    title: 'Boîte postale',
                    component: <Input type={"text"}
                                      value={data.loca_territoire.postal_code}
                                      onChange={(e) => {
                                          setLocalisation({
                                              ...localisation,
                                              postal_code: e.target.value
                                          })
                                      }}
                                      width={"300px"}
                    />
                },
                {
                    id: "email",
                    description: "Contiens une adresse email de contact de l'établissement.",
                    title: 'Email',
                    component: <Input type={"email"}
                                      value={data.loca_territoire.email}
                                      onChange={(e) => {
                                          setLocalisation({
                                              ...localisation,
                                              email: e.target.value
                                          })
                                      }}
                                      {...getFieldProps("email")}
                                      width={"300px"}
                    />
                }, {
                    id: "telephone",
                    description: "Contiens les numéros de téléphone de l'établissement.",
                    title: 'Téléphones',
                    component: <PhoneInput
                        value={data.loca_territoire.telephone}
                        onChange={(phones) => {
                            setLocalisation({
                                ...localisation,
                                telephone: phones
                            })
                        }}
                        {...getFieldProps("telephone")}
                        width={"300px"}
                    />
                },
            ]

            return <FormComponent
                id={"loca_terri"}
                status={validationStatus.status}
                isCollapsable={true} title={"Localisation dans l'Administration Territoriale"}>
                {
                    formElements.map((item, index) => (
                        <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                              justifyContent={"space-between"}>

                            <Text
                                id={item.id}
                                data-description={item.description}
                                w={"200px"} fontSize='md' textAlign='start'>
                                {item.title}
                            </Text>
                            {item.component}
                        </Flex>
                    ))
                }

            </FormComponent>

        }
    }

    return render()
}
import React from "react";
import {
    Box,
    SimpleGrid,

} from "@chakra-ui/react";

import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";
import {StudentByYear} from "./components/StudentByYear";
import {StudentByRedoublant} from "./components/StudentByRedoublant";
import {StudentByHandicap} from "./components/StudentByHandicap";
import {StudentParticularCase} from "./components/StudentParticularCase";
import MiniStatistics from "../../../components/card/MiniCard";

export function StudentsRecord() {
    const section_name = 'students_record'
    const {section_data: students_record, isLoading} = useGetSectionData<StudentsRecord>(section_name);
    return (
       <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
                <SimpleGrid columns={1} gap='20px' mb='20px'>
                    <MiniStatistics name='Effectif Total'
                                    value={isLoading ? 'Chargement' : Object.values(students_record.student_enrollment_by_age).reduce((total, classData) => {
                                        return total + Object.values(classData.years).reduce((classTotal, yearData) => {
                                            const boys = yearData.boys !== null ? yearData.boys : 0;
                                            const girls = yearData.girls !== null ? yearData.girls : 0;
                                            return classTotal + boys + girls;
                                        }, 0);
                                    }, 0)}/>
                    <StudentByYear section={section_name} data={students_record}/>
                    <StudentByRedoublant section={section_name} data={students_record}/>
                    <StudentByHandicap section={section_name} data={students_record}/>
                    <StudentParticularCase section={section_name} data={students_record}/>

                </SimpleGrid>}
        </Box>

    )
}
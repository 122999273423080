import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';

import App from "./App";
import theme from "./theme/theme";
import {configureStore} from "@reduxjs/toolkit";
import reducers from "./slices";
import {schoolsApi} from "./services/Schools";
import {Provider} from "react-redux";
import {ChakraProvider} from "@chakra-ui/react";
import {authApi} from "./services/authService";
import {liteClient as algoliasearch} from "algoliasearch/lite";
import {InstantSearch} from "react-instantsearch";

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(schoolsApi.middleware)
        .concat(authApi.middleware)
})
const searchClient = algoliasearch("WE4QVP6V4D", "cc9662c483850facc78669e2df10aeb3");

ReactDOM.render(
    <Provider store={store}>
        <InstantSearch
            searchClient={searchClient}
            indexName="projetses"
        >
            <ChakraProvider theme={theme}>
                <App/>
            </ChakraProvider>
        </InstantSearch>
    </Provider>,
    document.getElementById('root')
);

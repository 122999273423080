// Chakra imports
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, Button,
    Flex,
    Input,
    Select,
    Switch,
    Tab,
    TabList, TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';


import React from "react";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import {useForm} from "react-hook-form";

export default function Local(props: {
    selectedLocal: Office | null,
    setLocalList: (localS: Office[]) => void,
    title: string,
    onClose: () => void,
    section: string,
    helpMessage?: string,
    [x: string]: any
}) {
    const {
        title,
        selectedLocal,
        setLocalList,
        section,
        onClose,
        formItems, helpMessage, ...rest
    } = props;
    const {
        register,
        handleSubmit
    } = useForm()
    // Chakra Color Mode
    const submitForm = (data: Staff) => {

        onClose()
    }
    // @ts-ignore
    return (
        // @ts-ignore
        <form onSubmit={handleSubmit(submitForm)}>
            <Card>
                <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                    <Text

                        fontSize='lg'
                        fontWeight='700'

                    >
                        {title}
                    </Text>
                    <Help helpMessage={helpMessage}/>
                </Flex>
                <Box w='100%'>
                    <Tabs variant='soft-rounded' colorScheme={"brand"}>
                        <TabList>
                            <Tab>1</Tab>
                            <Tab>2</Tab>
                            <Tab>3</Tab>
                            <Tab>4</Tab>
                            <Tab>5</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nom
                                    </Text>
                                    <Input
                                        {...register("name")}
                                        defaultValue={selectedLocal.name}
                                        width={"300px"}
                                        type={"text"}/>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Année de mise en service
                                    </Text>
                                    <Input
                                        {...register("year_of_commissioning")}
                                        defaultValue={selectedLocal.year_of_commissioning}
                                        type="number"
                                        min="1900" max="2099" step="1"
                                        width={"300px"}
                                    />
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Cocher si non utilisée
                                    </Text>
                                    <Switch
                                        {...register("is_unused")}
                                        // @ts-ignore
                                        defaultValue={selectedLocal.is_unused}
                                        colorScheme="brand"
                                    />
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Superficie
                                    </Text>
                                    <Input
                                        defaultValue={selectedLocal.surface_area}
                                        type="number"
                                        min={0}
                                        width={"300px"}
                                    />
                                </Flex>

                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Type de local
                                    </Text>
                                    <Select
                                        width={"300px"}
                                        {...register("local_type")}
                                        placeholder={"Sélectionnez un type de local"}
                                        defaultValue={selectedLocal.local_type}
                                    >
                                        <option selected value="Salle">Salle</option>
                                        <option value="Bureau">Bureau</option>
                                        <option value={"Magasin"}>Magasin</option>
                                        <option value={"Latrine"}>Latrine</option>
                                        <option value={"Cuisine"}>Cuisine</option>
                                        <option value={"Bibliothèque"}>Bibliothèque</option>
                                        <option value={"Salle informatique"}>Salle informatique</option>
                                        <option value={"Logement"}>Logement</option>
                                    </Select>
                                </Flex>


                            </TabPanel>
                            <TabPanel>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nature des murs
                                    </Text>
                                    <Select
                                        {...register("wall_material")}
                                        placeholder={"Sélectionnez la nature des murs"}
                                        defaultValue={selectedLocal.wall_material}
                                        width={"300px"}
                                    >
                                        <option selected value="en dur">En dur</option>
                                        <option value="semi-dur / banco">Semi-dur / banco</option>
                                        <option value="autre: Planche / bambou">Autre: Planche / bambou</option>
                                        <option value="sans mur">Sans mur</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Etat des murs
                                    </Text>
                                    <Select
                                        {...register("wall_condition")}
                                        defaultValue={selectedLocal.wall_condition}
                                        placeholder={"Sélectionnez l'état des murs"}
                                        width={"300px"}
                                    >
                                        <option selected value="bon / acceptable">Bon / Acceptable</option>
                                        <option value="mauvais">Mauvais</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nature du toit
                                    </Text>
                                    <Select
                                        {...register("roof_material")}
                                        width={"300px"}
                                        placeholder={"Sélectionnez la nature du toit"}
                                        defaultValue={selectedLocal.roof_material}
                                    >
                                        <option selected value="tôles">Tôles</option>
                                        <option value="tuiles / fibro ciment / dalles">Tuiles / Fibro ciment / Dalles
                                        </option>
                                        <option value="banco">Banco</option>
                                        <option value="paille">Paille</option>
                                        <option value="sans toit">Sans toit</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Etat du toit
                                    </Text>
                                    <Select
                                        {...register("roof_condition")}
                                        placeholder={"Sélectionnez l'état du toit"}
                                        defaultValue={selectedLocal.roof_condition}
                                        width={"300px"}
                                    >
                                        <option selected value="bon / acceptable">Bon / Acceptable</option>
                                        <option value="mauvais">Mauvais</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nature des portes
                                    </Text>
                                    <Select
                                        {...register("door_material")}
                                        placeholder={"Sélectionnez la nature des portes"}
                                        defaultValue={selectedLocal.door_material}
                                        width={"300px"}
                                    >
                                        <option selected value="métallique">Métallique</option>
                                        <option value="tôle / bois">Tôle / Bois</option>
                                        <option value="non installées">Non installées</option>
                                    </Select>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Nature des fenêtres
                                    </Text>
                                    <Select
                                        {...register("wall_material")}
                                        placeholder={"Sélectionnez la nature des fenêtres"}
                                        defaultValue={selectedLocal.wall_material}
                                        width={"300px"}
                                    >
                                        <option selected value="persiennes">Persiennes</option>
                                        <option value="volets">Volets</option>
                                        <option value="claustras">Claustras</option>
                                        <option value="non installées">Non installées</option>
                                    </Select>
                                </Flex>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Financement
                                    </Text>
                                    <Select
                                        {...register("funding")}
                                        placeholder={"Sélectionnez le financement"}
                                        defaultValue={selectedLocal.funding}
                                        width={"300px"}
                                    >
                                        <option selected value="collectivités locales">Collectivités locales</option>
                                        <option value="APE">APE</option>
                                        <option value="aide extérieure">Aide extérieure</option>
                                        <option value="autres / non déterminé">Autres / Non déterminé</option>
                                    </Select>
                                </Flex>


                            </TabPanel>
                            <TabPanel>
                                <Accordion w='100%' mb='20px' allowToggle>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Text w={"200px"} fontSize='md'
                                                      textAlign='start'>
                                                    Mobilier des enseignants
                                                </Text>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Flex flexDirection='column'>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Bureau
                                                        maître
                                                        avec
                                                        chaise
                                                    </Text>
                                                    <Input

                                                        placeholder={"Nombre de bureau"}
                                                        defaultValue={selectedLocal.teacher_furniture.teacher_desk_with_chair}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Armoire
                                                        ou
                                                        placard
                                                    </Text>
                                                    <Input
                                                        placeholder={"Nombre d'armoire"}
                                                        defaultValue={selectedLocal.teacher_furniture.cupboard_or_closet}
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>

                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Tableau noir
                                    </Text>
                                    <Input
                                        defaultValue={selectedLocal.blackboard}
                                        type="number"
                                        width={"300px"}
                                    />
                                </Flex>
                            </TabPanel>
                            <TabPanel>


                                <Flex w='100%' mb='20px' alignItems={"center"}
                                      justifyContent={"space-between"}>

                                    <Text w={"200px"} fontSize='md' textAlign='start'>
                                        Année d'étude
                                    </Text>
                                    <Select
                                        w={"300px"}
                                        {...register("study_years")}
                                        placeholder={"Sélectionnez une année d'étude"}
                                        defaultValue={selectedLocal.study_years}
                                    >
                                        <option value="CI">CI</option>
                                        <option value="CP">CP</option>
                                        <option value={"CE1"}>CE1</option>
                                        <option value={"CE2"}>CE2</option>
                                        <option value={"CM1"}>CM1</option>
                                        <option value={"CM2"}>CM2</option>
                                    </Select>
                                </Flex>
                                <Accordion w='100%' mb='20px' allowToggle>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Text w={"200px"} fontSize='md'
                                                      textAlign='start'>
                                                    Mobilier élèves
                                                </Text>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Flex flexDirection='column'>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Type de local
                                                    </Text>
                                                    <Input

                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>
                                                <Flex w='100%' mb='20px' alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Text w={"200px"}
                                                          fontSize='md' textAlign='start'>
                                                        Type de local
                                                    </Text>
                                                    <Input
                                                        type="number"
                                                        width={"300px"}
                                                    />
                                                </Flex>

                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>



                            </TabPanel>

                        </TabPanels>
                    </Tabs>
                </Box>
                <Button my={"10px"} bgColor={"brand.900"} color={"white"}>Enregistrer</Button>

            </Card>
        </form>
    );
}

import {Flex, Switch, Text} from "@chakra-ui/react";
import * as React from "react";
import SelectCheckbox from "../../../../components/SelectCheckBox/SelectCheckBox";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import FormComponent from "../../../../components/form/FormComponent";
import useFormValidator from "../../../../hooks/useFormValidator";
interface OutdoorFacilitiesInterface {
    is_playground_shaded: boolean;
    has_garden_or_school_field: boolean;
    has_adaptations_for_special_needs_children: boolean;

}
export function OutdoorFacilities({section, data}: {
    section: string,
    data: GeneralInfo
}) {
    const [value,
        setValue] = useSyncReduxCache(data.outdoor_facilities,
        section,
        "detail.data.outdoor_facilities");
    const {validationStatus} = useFormValidator<OutdoorFacilitiesInterface>(value, {});

    const outdoorFacilities: FormElement[] = [
        {
            title: 'La cour de la récréation est-elle ombragée ?',
            id: 'is_playground_shaded',
            description: "Spécifie si la cour de récréation est ombragée ou non",
            component: <Switch
                isChecked={value.is_playground_shaded}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        is_playground_shaded: !prevState.is_playground_shaded
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },
        {
            title: 'L\'école dispose-t-elle d\'un jardin ou d\'un champ scolaire ?',
            id: 'has_garden_or_school_field',
            description: "Spécifie si l'école dispose d'un jardin ou d'un champ scolaire",
            component: <Switch
                isChecked={value.has_garden_or_school_field}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_garden_or_school_field: !prevState.has_garden_or_school_field
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        }, {
            title: 'L\'école a-t-elle des aménagements adaptés aux enfants à besoins spécifiques ?',
            id: 'has_adaptations_for_special_needs_children',
            description: "Spécifie si l'école dispose d'aménagements adaptés aux enfants à besoins spécifiques",
            component: <Switch
                isChecked={value.has_adaptations_for_special_needs_children}
                onChange={() => {
                    setValue(prevState => ({
                        ...prevState,
                        has_adaptations_for_special_needs_children: !prevState.has_adaptations_for_special_needs_children
                    }))
                }}
                variant='main' colorScheme='brandScheme' size='md'/>
        },

    ]
    return  <FormComponent
        id={"outdoor_facilities"}
        status={validationStatus.status}
        isCollapsable={true} title={"Aménagements extérieurs"}>
        {
            outdoorFacilities.map((item, index) => (
                <Flex
                     id={item.id}
                                data-description={item.description}
                    w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text w={"200px"} fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))
        }
    </FormComponent>

}